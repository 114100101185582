import React, { useEffect, useState } from "react";
import PromotedJobsTable from "../users/components/PromotedJobsTable";
import { Box, Modal, TextField, Autocomplete, Drawer } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  getUploadedJobBannersAction,
  updateJobBannerAction,
  uploadJobBannerAction,
} from "../../../redux/admin/action";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import pdfIcon from "../../../assets/images/pdfIcon.svg";
import moment from "moment";
import { formatFileSize } from "../../../utils/formatFilleSize";
import dayjs from "dayjs";
import calendar from "../../../assets/images/calender.svg";
import clock from "../../../assets/images/clock.svg";
import { Close } from "@mui/icons-material";
import { IoMdArrowDropdown } from "react-icons/io";

const PromotedJobs = () => {
  const { token } = useSelector((state) => state.Authsection);
  const dispatch = useDispatch();
  const { uploadJobBanner, uploadJobBannerLoading } = useSelector(
    (state) => state.Adminsection
  );
  const { updateJobBanner, updateJobBannerLoading } = useSelector(
    (state) => state.Adminsection
  );
  const [isJobPostModalOn, setIsJobPostModalOn] = useState(false);
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobList, setJobList] = useState([]);
  const maxFileSize = 3 * 1024 * 1024;
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const uploadPreset = process.env.REACT_APP_CLOUDINARY_PRESET_NAME;
  const [isJobBannerUploaded, setIsJobBannerUploaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [promotedJobDetails, setPromotedJobDetails] = useState(null);
  const [promotedJob, setPromotedJob] = useState(null);
  const [loading, setLoading] = useState(false);

  const documentUploadStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
  };

  useEffect(() => {
    const getAllJobs = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/admin/jobs/get-job-list`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        setJobList(data?.data?.job_list);
      } catch (error) {
        console.log(error);
      }
    };

    getAllJobs();
  }, []);

  useEffect(() => {
    if (uploadJobBanner && uploadJobBanner.success && isJobBannerUploaded) {
      toast.success(uploadJobBanner.message);
      dispatch(getUploadedJobBannersAction({ limit: 10, page: 1 }));
      setIsJobPostModalOn(false);
      setIsJobBannerUploaded(false);
    }
  }, [uploadJobBanner]);

  useEffect(() => {
    if (updateJobBanner && updateJobBanner.success && isJobBannerUploaded) {
      toast.success(updateJobBanner.message);
      dispatch(getUploadedJobBannersAction({ limit: 10, page: 1 }));
      setIsJobPostModalOn(false);
      setIsJobBannerUploaded(false);
    }
  }, [updateJobBanner]);

  const uploadJobBannerCall = () => {
    if (promotedJob) {
      if (startDate && endDate) {
        const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
        const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

        const updatePayload = {
          promotion_id: promotedJob.promotion_id,
          banner_url: image ? image : promotedJob.banner_url,
          start_time: startDate ? startDate : formattedStartDate,
          end_time: endDate ? endDate : formattedEndDate,
        };

        // console.log(updatePayload);
        // return;

        dispatch(updateJobBannerAction(updatePayload));
        setIsJobBannerUploaded(true);
      } else {
        toast.error("Please fill all fields before uploading.");
      }
    } else {
      if (startDate && endDate && selectedJob) {
        const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
        const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

        const payload = {
          banner_url: image,
          start_time: formattedStartDate,
          end_time: formattedEndDate,
          job_id: selectedJob.id,
        };

        // console.log(payload);
        // return;

        dispatch(uploadJobBannerAction(payload));
        setIsJobBannerUploaded(true);
      } else {
        toast.error("Please fill all fields before uploading.");
      }
    }
  };

  const uploadImage = (files) => {
    setLoading(true);
    const selected = files[0];
    setSelectedFile(selected);
    if (selected && selected.size <= maxFileSize) {
      const formData = new FormData();
      formData.append("file", selected);
      formData.append("upload_preset", uploadPreset);
      axios
        .post(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, formData)
        .then((res) => {
          setImage(res.data.secure_url);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      notifyfail("File size must be less than 3 MB");
    }
  };

  const notifyfail = (value) => {
    toast.error(value);
  };

  useEffect(() => {
    if (promotedJob) {
      setIsJobPostModalOn(true);
      setStartDate(dayjs(promotedJob.start_date));
      setEndDate(dayjs(promotedJob.end_date));
      setImage(promotedJob.banner_url);
    }
  }, [promotedJob]);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setIsJobPostModalOn(false);
      setPromotedJob(null);
    }
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        sx={{
          width: "70%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "70%",
            boxSizing: "border-box",
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        className="dashboard"
      >
        <div className="bg-white rounded-xl w-full p-10">
          <div className="flex items-center justify-end w-full">
            <div
              onClick={() => {
                setOpen(false);
                setPromotedJobDetails(null);
              }}
              className="w-12 h-12 rounded-full hover:bg-[#145DEB42] px-2 py-3 cursor-pointer font-semibold text-center bg-[#145DEB1A] text-primary"
            >
              <Close />
            </div>
          </div>

          <div className="flex pl-14 items-center gap-2 mt-6 text-[12px]">
            Posted by {promotedJobDetails?.posted_by} on{" "}
            <span className="text-primary">
              {moment(promotedJobDetails?.date).format("DD MMMM YYYY")}
            </span>
          </div>

          <div className="border-b border-[#E5E7EB] pl-14 py-10">
            <h2 className="text-sm text-gray-400">Job description</h2>
            <p className="my-3 text-[16px]">
              {promotedJobDetails?.description}
            </p>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
              I am looking for
            </h2>

            <div className="flex mt-6 mb-6 gap-6">
              {promotedJobDetails?.lookingFor?.map((item, index) => (
                <div
                  className="rounded-[50px] border border-[#366CFE]  py-[10px] px-[15px] text-[#366CFE] text-xs bg-[#EDF2FF]"
                  key={index}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
              Location
            </h2>
            <p className="my-3 text-sm text-secondary">
              {promotedJobDetails?.address}
            </p>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <div className="">
              <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
                Start date / time
              </h2>
              {promotedJobDetails?.start_time?.map((item, index) => (
                <div className="flex my-6" key={index}>
                  <div>
                    <span>Date</span>
                    <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                      <p className="pt-[8px] mr-[30px] ml-[5px]">
                        {moment(item.date).format("DD/MM/YYYY")}
                      </p>
                      <img
                        width={500}
                        height={500}
                        src={calendar}
                        className="w-10 h-10"
                        alt="img1"
                      />
                    </div>
                  </div>
                  <div className="mr-6 h-[50px] w-[40px] border-b border-[#89909D]"></div>
                  <div>
                    <span>From time</span>
                    <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                      <p className="pt-[8px] mr-[40px] ml-[5px]">
                        {moment(item.fromTime).format("hh:mm a")}
                      </p>
                      <img
                        width={500}
                        height={500}
                        src={clock}
                        className="w-10 h-10"
                        alt="img1"
                      />
                    </div>
                  </div>
                  <div>
                    <span>To time</span>
                    <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                      <p className="pt-[8px] mr-[40px] ml-[5px]">
                        {moment(item.toTime).format("hh:mm a")}
                      </p>
                      <img
                        width={500}
                        height={500}
                        className="w-10 h-10"
                        src={clock}
                        alt="img1"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <div className="w-1/3">
              <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
                Budget
              </h2>
              <p className="text-gray-400 pt-4">
                £{promotedJobDetails?.budget} x hr
              </p>
            </div>
          </div>
        </div>
      </Drawer>
      <div className="w-full min-h-screen bg-dull py-6 md:px-10 px-2">
        <div className="flex justify-end">
          <button
            className="bg-[#0070de] hover:!bg-[#4585FF] text-white p-2 rounded-[5px] w-[230px]"
            onClick={() => setIsJobPostModalOn(true)}
          >
            Promote Jobs
          </button>
        </div>

        <PromotedJobsTable
          setDrawerOpen={(data) => setOpen(data)}
          setPromotedJobDetails={(data) => setPromotedJobDetails(data)}
          handlePromotedJobEdit={(data) => setPromotedJob(data)}
        />

        <Modal
          disableBackdropClick
          open={isJobPostModalOn}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={documentUploadStyle} className="w-[80%] md:w-[50%]">
            <div className="w-full poppins">
              <div className="flex items-center justify-between">
                <div className=" flex justify-center text-[18px] font-bold">
                  <p>{promotedJob ? "Update" : "Upload"} Job Banner</p>
                </div>
                <div
                  onClick={() => {
                    setIsJobPostModalOn(false);
                    setPromotedJob(null);
                    setStartDate(null);
                    setEndDate(null);
                    setImage(null);
                  }}
                  className="w-12 h-12 rounded-full hover:bg-[#145DEB42] px-2 py-3 cursor-pointer font-semibold text-center bg-[#145DEB1A] text-primary"
                >
                  <Close />
                </div>
              </div>
              <div className="my-5 gap-5 flex flex-row justify-between">
                {promotedJob ? (
                  <div className="border-[1px] border-solid border-gray-200 w-full py-4 px-3 rounded-md text-gray-400 flex justify-between items-center">
                    <p>{promotedJob?.job_title}</p>
                    <IoMdArrowDropdown color="#d9d9d9" size={20} />
                  </div>
                ) : (
                  <Autocomplete
                    // disabled={promotedJob}
                    options={jobList}
                    getOptionLabel={(option) => option.title}
                    sx={{ width: "100%" }}
                    onChange={(event, newValue) => setSelectedJob(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Job to promote"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              </div>
              <div className="my-5 gap-5 flex flex-col justify-between md:flex-row">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Enter Start Date"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Enter End Date"
                      onChange={(newValue) => setEndDate(newValue)}
                      minDate={startDate}
                      value={endDate}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="border-dashed border-2 border-[#D8D8D8] h-[250px] max-w-[678px] my-8 flex flex-col items-center justify-center">
                {image ? (
                  <>
                    <div className="w-60 h-16 bg-[#EAF0FF] flex justify-evenly items-center rounded">
                      <div>
                        <img
                          src={pdfIcon}
                          height={15}
                          width={15}
                          alt="pdf-icon"
                        />
                      </div>
                      <div>
                        <div className="text-sm">
                          {selectedFile?.name ? selectedFile?.name : "File"}
                        </div>
                        <div className="flex">
                          <div className="text-[8px] text-[#366CFE] py-1 pr-4">
                            {selectedFile?.lastModified
                              ? moment(selectedFile?.lastModified).format(
                                  "DD/MM/YYYY"
                                )
                              : moment(new Date()).format("DD/MM/YYYY")}
                          </div>
                          <div className="text-[8px] text-[#999999] py-1">
                            {selectedFile?.size
                              ? formatFileSize(selectedFile?.size)
                              : "3 MB"}
                          </div>
                        </div>
                      </div>

                      {/* <div onClick={deleteSelectedFile}>
                <Image
                  src={redDeleteIcon}
                  height={15}
                  width={15}
                  alt="delete icon"
                ></Image>
              </div> */}
                    </div>
                    {promotedJob && (
                      <div>
                        <input
                          type="file"
                          id="fileInput"
                          onChange={(e) => uploadImage(e.currentTarget.files)}
                          className="hidden"
                        />
                        <label
                          htmlFor="fileInput"
                          className="cursor-pointer bg-primary w-[135px] h-9 rounded-full text-white justify-center flex items-center my-2"
                        >
                          Select
                        </label>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center">
                    <div className="py-8 px-1 text-[#999999] max-w-[254px] text-[10px] text-center">
                      Please upload a copy of your valid identification in PNG,
                      JPEG format, no larger than 3 MB in size
                    </div>

                    <input
                      type="file"
                      id="fileInput"
                      onChange={(e) => uploadImage(e.currentTarget.files)}
                      className="hidden"
                    />
                    <label
                      htmlFor="fileInput"
                      className="cursor-pointer bg-primary w-[135px] h-9 rounded-full text-white justify-center flex items-center"
                    >
                      Select
                    </label>
                  </div>
                )}
              </div>
              <div className="mt-10 flex justify-end">
                <button
                  className="bg-[#0070de] text-white p-2 rounded-[5px] w-[100px] flex justify-center"
                  onClick={uploadJobBannerCall}
                >
                  {uploadJobBannerLoading ||
                  updateJobBannerLoading ||
                  loading ? (
                    <img
                      src={"/loading.svg"}
                      alt="loading"
                      className="w-6 h-6"
                    />
                  ) : promotedJob ? (
                    "Update"
                  ) : (
                    "Upload"
                  )}
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default PromotedJobs;
