import React, { useCallback, useEffect, useState } from "react";
import { Box, Drawer, Modal } from "@mui/material";
import SingleJobPage from "./components/SingleJobPage";
import ListLoader from "../../loader/listLoader";
import moment from "moment";
import active from "../../../assets/images/dashboard/active.svg";
import filled from "../../../assets/images/dashboard/filled.svg";
import open from "../../../assets/images/dashboard/open.svg";
import suspended from "../../../assets/images/dashboard/suspended.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobsAction,
  getJobsBetweenDatesAction,
} from "../../../redux/subadmin/action";
import Pagination from "../../common/Pagination";
import { IoMdClose } from "react-icons/io";
import toast from "react-hot-toast";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { CiSearch } from "react-icons/ci";
import { useLocation } from "react-router-dom";
import { IoChatbubblesOutline } from "react-icons/io5";
import GeneralChat from "../../ai-chat-bot/general-chat/GeneralChat";

const PAGE_SIZE = 10;

function Jobs() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [open, setOpen] = useState(false);
  const [currJob, setCurrJob] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [totalResult, setTotalResult] = useState(100);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [emailInput, setEmailInput] = useState("");
  const [emails, setEmails] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [exportAttempted, setExportAttempted] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const dispatch = useDispatch();
  const { getJobs, getJobsLoading, isJobUpdated } = useSelector(
    (state) => state.SubadminSection
  );
  const { getJobsBetweenDates, getJobsBetweenDatesLoading } = useSelector(
    (state) => state.SubadminSection
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.length >= 3 || searchTerm.length === 0) {
        setDebouncedSearchTerm(searchTerm);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const status = searchParams.get("status");
    if (status) {
      console.log(status, "status");
    }
  }, []);

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]);
    const csvRows = [
      header.join(","),
      ...data.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ];
    return csvRows.join("\n");
  };
  const notifyfail = (value) => {
    toast.error(value);
  };

  const replacer = (key, value) => (value === null ? "" : value);

  const downloadCSV = (csv, filename = "jobs.csv") => {
    const csvBlob = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement("a");
    link.href = csvUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (getJobsBetweenDates && getJobsBetweenDates.data && exportAttempted) {
      if (getJobsBetweenDates.data.length > 0) {
        const csv = convertToCSV(getJobsBetweenDates.data);
        downloadCSV(csv, `jobs.csv`);
        setDatepickerOpen(false);
      } else if (exportAttempted) {
        toast.error("No jobs were found between these dates");
      }
    }
  }, [getJobsBetweenDates, exportAttempted]);

  useEffect(() => {
    if (totalResult > 0) {
      setTotalPages(Math.ceil(totalResult / PAGE_SIZE));
    }
  }, [totalResult]);

  useEffect(() => {
    if (getJobs?.meta?.total > 0) {
      setTotalResult(getJobs?.meta?.total);
    }
  }, [getJobs]);

  useEffect(() => {
    const status = searchParams.get("status");

    dispatch(
      getJobsAction({
        limit: limit,
        skip: skip,
        keywords: debouncedSearchTerm ? debouncedSearchTerm : "",
        status: "",
      })
    );
  }, [skip]);

  useEffect(() => {
    const status = searchParams.get("status");

    dispatch(
      getJobsAction({
        limit: limit,
        skip: skip,
        keywords: debouncedSearchTerm,
        status: status ? status : "",
      })
    );
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const status = searchParams.get("status");

    dispatch(
      getJobsAction({
        limit: limit,
        skip: skip,
        keywords: "",
        status: status ? status : "",
      })
    );
  }, [dispatch]);

  const handlePageChange = useCallback((page) => {
    setSkip(page);
  }, []);

  const getPageNumbers = useCallback(() => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }, [totalPages]);

  const handleOpen = (item) => {
    setOpen(true);
    setSelectedJob(item);
  };

  const handleClose = () => {
    setOpen(false);
    setEmails([]);
    setEmailInput("");
  };

  const handleEmailInputChange = (e) => {
    setEmailInput(e.target.value);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const newEmail = e.target.value.trim();
      if (newEmail && !emails.includes(newEmail)) {
        if (!validateEmail(newEmail)) {
          toast.error("Invalid email ID");
        } else {
          setEmails([...emails, newEmail]);
          setEmailInput("");
        }
      } else {
        toast.error("Email already added");
      }
    }
  };

  const removeEmail = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 4,
    borderRadius: 5,
    height: 400,
    // marginHorizontal: 10,
  };

  const datePickerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    marginHorizontal: 10,
  };

  const documentUploadStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
  };

  const handleInvite = (job) => {
    console.log(emails, job);
  };

  const exportCSVWithDate = () => {
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

      let payload = {
        start: formattedStartDate,
        end: formattedEndDate,
      };

      dispatch(getJobsBetweenDatesAction(payload));
      setStartDate(null);
      setEndDate(null);
      setExportAttempted(true);
    } else {
      toast.error("Please select both start and end dates.");
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        sx={{
          width: "70%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "70%",
            boxSizing: "border-box",
          },
        }}
        open={currJob !== null}
        onClose={() => setCurrJob(null)}
        className="dashboard"
      >
        {currJob !== null ? (
          <SingleJobPage setCurrJob={setCurrJob} data={currJob} />
        ) : null}
      </Drawer>

      <div className="flex flex-col items-start justify-between md:flex-row items-center pt-[30px] pb-0 p-10 gap-5">
        <div className="border p-2 rounded-[15px] w-full bg-white flex items-center gap-2">
          <CiSearch size={25} color="gray" />
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search jobs (type atleast three characters)"
            className="outline-0 w-full"
          />
        </div>

        <button
          className="bg-[#0070de] hover:!bg-[#4585FF] text-white p-2 rounded-[5px] w-[230px]"
          onClick={() => setDatepickerOpen(true)}
        >
          Export to CSV
        </button>
      </div>
      <div className="w-full min-h-screen bg-dull md:py-6 py-3 md:px-10 px-3">
        <div className="w-full bg-white rounded-xl">
          <div className="mb-10 font-medium text-sm border-b px-10 pt-8 pb-2">
            <span className="pb-2 mb-2 border-[#145EEE] border-b-2">
              {getJobs?.meta?.total} jobs found
            </span>
          </div>
          <div className="md:px-10 px-4 pb-4">
            {getJobsLoading ? (
              <ListLoader />
            ) : getJobs?.data?.length > 0 ? (
              getJobs?.data?.map((item, i) => (
                <div
                  key={i}
                  className="rounded-md cursor-pointer relative hover:bg-[#366bfe16] border-grey border-[0.5px] my-5 shadow-lg"
                >
                  <div className="py-3 px-4 flex items-start justify-between w-full">
                    <div className="w-full">
                      <div className="flex justify-between gap-5">
                        <button onClick={() => setCurrJob(item)}>
                          <h3 className="md:text-xl text-xs font-semibold text-black uppercase">
                            {item?.title} | {item?.user?.first_name}{" "}
                            {item?.user?.last_name}
                          </h3>
                        </button>
                        <div
                          onClick={(e) => e.stopPropagation()}
                          className="flex items-center justify-around"
                        >
                          <span className="text-[#313030] md:text-xs text-[8px] font-medium">
                            Posted:
                            <span className="whitespace-nowrap">
                              {moment(item?.meta?.created_on).fromNow()}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="flex md:flex-row flex-col mt-2 gap-2 w-full md:items-center">
                        <div className="flex md:text-sm text-[10px] gap-2 items-center">
                          {item?.address}
                        </div>
                        <div className="flex text-sm gap-2 md:items-center">
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6.70426 7.29835C9.22368 9.81707 9.79523 6.90319 11.3994 8.5062C12.9459 10.0523 13.8347 10.362 11.8753 12.3209C11.6299 12.5181 10.0705 14.8911 4.59028 9.41242C-0.890618 3.93305 1.481 2.37207 1.6783 2.1267C3.64244 0.162429 3.94686 1.05645 5.49336 2.60252C7.09749 4.20621 4.18484 4.77963 6.70426 7.29835Z"
                              stroke="#366CFE"
                              strokeWidth="1.35955"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="leading-[21px]">{item?.user?.phone}</p>
                        </div>
                      </div>
                      <div className="flex my-4">
                        <div className="flex flex-wrap gap-4 m-4">
                          <p className="md:text-base text-[10px] bg-[#F6F5F5] text-[#5B5B5B] font-semibold px-4 py-1 rounded-lg">
                            £{item?.budget ?? 0} x hr
                          </p>
                          <p className="md:text-base text-[10px] bg-[#F6F5F5] text-[#5B5B5B] font-semibold px-4 py-1 rounded-lg">
                            {moment(item.startDateTime[0]?.date).format(
                              "DD/MM/YYYY"
                            )}
                          </p>
                          <p className="md:text-base text-[10px] bg-[#F6F5F5] text-[#5B5B5B] font-semibold px-4 py-1 rounded-lg">
                            {item?.startDateTime?.length ?? 1} days{" "}
                          </p>
                          <p className="md:text-base text-[10px] bg-[#F6F5F5] text-[#5B5B5B] font-semibold px-4 py-1 rounded-lg">
                            AM - PM
                          </p>
                        </div>

                        {item.status === "open" ? (
                          <img
                            width={800}
                            height={800}
                            alt=""
                            src={active}
                            className="w-16 h-16  object-contain"
                          />
                        ) : item.status === "suspended" ? (
                          <img
                            width={800}
                            height={800}
                            alt=""
                            src={suspended}
                            className="w-16 h-16 object-contain"
                          />
                        ) : item.status === "filled" ? (
                          <img
                            width={800}
                            height={800}
                            alt=""
                            src={filled}
                            className="w-16 h-16  object-contain"
                          />
                        ) : item.status === "open" ? (
                          <img
                            width={800}
                            height={800}
                            alt=""
                            src={open}
                            className="w-16 h-16  object-contain"
                          />
                        ) : (
                          <img
                            width={800}
                            height={800}
                            alt=""
                            src={item.status}
                            className="w-16 h-16  object-contain"
                          />
                        )}
                      </div>
                      <div className="flex justify-between gap-4">
                        <p className="text-[#565656]  text-sm font-normal my-3">
                          {item.description}
                          <span
                            onClick={() => setCurrJob(item)}
                            className="text-primary cursor-pointer"
                          >
                            {" "}
                            Read More{" "}
                          </span>
                        </p>
                        <div className="flex flex-row gap">
                          <button
                            onClick={() => handleOpen(item)}
                            className={`bg-primary w-[42px] h-[42px] rounded-[10px] md:text-sm text-xs text-white justify-center flex items-center mr-3 `}
                          >
                            <IoChatbubblesOutline size={25} color="white" />
                          </button>
                          <div className="bg-[#E4E2E0] p-1 h-[44px] w-[44px] flex items-center justify-center rounded-lg">
                            <button
                              onClick={() => setCurrJob(item)}
                              className="rounded-full border-2  border-black p-1 h-[21px] w-[21px] flex items-center justify-center"
                            >
                              <span className="text-xs">i</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full text-center bg-white p-5 rounded-xl">
                <h1>No data yet.</h1>
              </div>
            )}
          </div>
        </div>
      </div>
      {totalPages > 0 && (
        <div className="pagination_box">
          <Pagination
            currentPage={skip}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            getPageNumbers={getPageNumbers}
          />
        </div>
      )}
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <GeneralChat
            selectedJob={selectedJob}
            onClose={handleClose}
            role={"admin"}
          />
        </Box>
      </Modal>

      <Modal
        open={datepickerOpen}
        onClose={() => setDatepickerOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={datePickerStyle}>
          <div className="text-center text-[20px] font-medium">
            Select Dates
          </div>
          <div className="my-5 gap-5 flex flex-col">
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Enter Start Date"
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Enter End Date"
                    onChange={(newValue) => setEndDate(newValue)}
                    minDate={startDate}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          <div className="mt-10 flex justify-center">
            <button
              className="bg-[#0070de] text-white p-2 rounded-[5px] w-[100px] flex justify-center"
              onClick={exportCSVWithDate}
            >
              {getJobsBetweenDatesLoading ? (
                <img src={"/loading.svg"} alt="loading" className="w-6 h-6" />
              ) : (
                <span>Export</span>
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Jobs;
