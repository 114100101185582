import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/images/footer/logo-footer.png";
import facebook from "../../../../assets/images/footer/facebook.svg";
import instagram from "../../../../assets/images/footer/instagram.svg";
import call_white from "../../../../assets/images/footer/call_white.svg";
import email_white from "../../../../assets/images/footer/email_white.svg";

const mailToLink = `mailto:info@igardu.com`;

function Footer() {
  return (
    <div className="bg-[#29273E] px-[20px] pb-[32px] pt-[50px] sm:pb-[60px] sm:pt-[120px]">
      <div className="max-w-[1440px] mx-auto flex flex-wrap justify-between gap-[40px]">
        <div className="max-w-[435px]">
          <img
            width={200}
            height={60}
            src={logo}
            alt="footer-logo"
            className="object-contain "
          />

          <p className="text-white text-[14px] sm:text-[16px] leading-[24px] sm:leading-[36px] my-[24px]">
            At iGardu, we're your gateway to career growth. Join us today for a
            seamless job hunting and talent acquisition experience.
          </p>

          <div className="flex gap-[8px] items-center">
            <img
              width={20}
              height={20}
              src={call_white}
              alt="footer-call_white"
              className="object-contain "
            />
            <a
              href="tel:>0121 272 9229"
              className="text-white text-[14px] m-0 p-0"
            >
              0121 272 9229
            </a>
          </div>

          <div className="flex gap-[8px] items-center mt-[12px]">
            <img
              width={20}
              height={20}
              src={email_white}
              alt="footer-email_white"
              className="object-contain "
            />
            <Link className="text-white text-[14px] m-0 p-0" to={mailToLink}>
              info@igardu.com
            </Link>
          </div>

          <div className="flex gap-[12px] items-center mt-[24px]">
            <a
              href="https://www.facebook.com/profile.php?id=61566000531490"
              target="_blank"
              rel="noreferrer"
            >
              <img
                width={24}
                height={24}
                src={facebook}
                alt="facebook"
                className="object-contain w-6 h-6"
              />
            </a>
            <a
              href="https://www.instagram.com/igardu_security/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                width={24}
                height={24}
                src={instagram}
                alt="instagram"
                className="object-contain w-6 h-6"
              />
            </a>
          </div>
        </div>

        <div className="flex gap-[40px] flex-col sm:flex-row sm:gap-[100px] flex-wrap ">
          <div className=" ">
            <h3 className="text-white text-[24px] font-[600] mb-[24px]">
              Quick Links
            </h3>

            <Link to="/about-us">
              <p className="mb-[12px] text-[#ffffff] text-[14px]  ">About us</p>
            </Link>
            <Link
              to={mailToLink}
              className="mb-[12px] block text-[#ffffff] text-[14px]"
            >
              Contact us
            </Link>
            <Link to="/how-it-works">
              <p className="mb-[12px] text-[#ffffff] text-[14px]  ">
                How it works
              </p>
            </Link>
            <Link to="/post-a-job">
              <p className="mb-[12px] text-[#ffffff] text-[14px]  ">
                Client Sign Up
              </p>
            </Link>
            <Link to="/trader-signup">
              <p className="mb-[12px] text-[#ffffff] text-[14px]  ">
                Guard Sign Up
              </p>
            </Link>
          </div>

          <div className="">
            <h3 className="text-white text-[24px] font-[600] mb-[24px]">
              Services
            </h3>

            <Link to="/post-a-job">
              <p className="mb-[12px] text-[#ffffff] text-[14px]  ">
                Post a job
              </p>
            </Link>
            <Link to="/">
              <p className="mb-[12px] text-[#ffffff] text-[14px]  ">
                Find Trades
              </p>
            </Link>
          </div>

          <div className="">
            <h3 className="text-white text-[24px] font-[600] mb-[24px]">
              Company
            </h3>

            <Link to="/terms-and-conditions">
              <p className="mb-[12px] text-[#ffffff] text-[14px]  ">
                Terms & Conditions
              </p>
            </Link>
            <Link to="/privacy-policy">
              <p className="mb-[12px] text-[#ffffff] text-[14px]  ">
                Privacy Policy
              </p>
            </Link>
            <Link to="/cookies-policy">
              <p className="mb-[12px] text-[#ffffff] text-[14px]  ">
                Cookies Policy
              </p>
            </Link>
          </div>
        </div>

        <div className="bg-[#B9B9B9] w-full mt-[40px] h-[1px]" />

        <div className="flex justify-between items-center w-full">
          <p className=" text-[#ffffff] text-[14px]">
            © 2023 iGardu. All rights reserved.
          </p>
          {/* <a
            href="https://lintechgroup.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            <p className=" text-[#ffffff] text-[14px]">
              Website Developed by Lintech Group
            </p>
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
