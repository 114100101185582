/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Typography, Modal, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { getJobsAction, patchJobAction } from "../../../../redux/subadmin/action";
import calendar from "../../../../assets/images/calender.svg";
import clock from "../../../../assets/images/clock.svg";

const SingleJobPage = ({
  data,
  setCurrJob,
  activeTab,
  handler,
  declinedHandler,
}) => {
  const [account, setAccount] = useState(data?.status ?? "open");
  const [openModal, setOpenModal] = useState(false);
  const [initial, setInitial] = useState(false);
  const dispatch = useDispatch();
  const [statusStyles, setStatusStyles] = useState({
    height: "40px",
    minWidth: "150px",
    borderRadius: "9999px",
    color: "#067647",
    backgroundColor: "#ECFDF3",
    border: "1px solid #ABEFC6",
  });

  const { patchJobStatusLoading, patchJobStatus } = useSelector(
    (state) => state.SubadminSection
  );

  useEffect(() => {
    if (account === "open") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#067647",
        backgroundColor: "#ECFDF3",
        border: "1px solid #ABEFC6",
      });
    } else if (account === "suspended") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#F2A50F",
        backgroundColor: "#F2A50F1A",
        border: "1px solid #F2A50F",
      });
    } else if (account === "closed") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#D92D20",
        backgroundColor: "#D92D201A",
        border: "1px solid #D92D20",
      });
    }
  }, [account]);

  useEffect(() => {
    if (patchJobStatus.success && initial === true) {
      toast.success(patchJobStatus.message);
      setOpenModal(false);
      setInitial(false);
      dispatch(
        getJobsAction({
          limit: 10,
          skip: 1,
          keywords: "",
          status: "",
        })
      );
    } else if (patchJobStatus.success === false && initial === true) {
      toast.error(patchJobStatus.message);
      setOpenModal(false);
      setInitial(false);
    }
  }, [dispatch, patchJobStatus]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20%",
    borderRadius: "20px",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
  };

  const statusTrn = {
    open: {
      button: "Activate",
      title: "Activate account",
      description: `Are you sure you want to activate this job?`,
      bg: "bg-[#3F88251A]",
      color: "#3F8825",
      bt: "bg-[#3F8825] text-white",
    },
    suspended: {
      button: "Suspend",
      title: "Suspend account",
      description: `Are you sure you want to suspend this job?`,
      bg: "bg-[#F2A50F1A]",
      color: "#F2A50F",
      bt: "bg-[#F2A50F] text-white",
    },
    closed: {
      button: "Close",
      title: "Close account",
      description: `Are you sure you want to update this user's Account status to ${account} ?`,
      bg: "bg-[#FEE4E2]",
      color: "#D92D20",
      bt: "bg-[#D92D20] text-white",
    },
  };

  const handleSelect = (value) => {
    setAccount(value);
    setOpenModal(true);
  };

  const callDispatch = () => {
    dispatch(
      patchJobAction({
        id: data.id,
        data: {
          status: account,
        },
      })
    );
    setInitial(true);
  };

  const infoIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={statusTrn[account]?.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <div
            className={`${statusTrn[account]?.bg} h-[40px] w-[40px] rounded-full flex justify-center items-center mb-4`}
          >
            {infoIcon}
          </div>
          <Typography
            // id="v-title"
            variant="h6"
            component="h2"
            className="font-black"
          >
            {statusTrn[account]?.title}
          </Typography>
          <Typography sx={{ my: 2 }}>
            {statusTrn[account]?.description}
          </Typography>
          <div className="flex justify-between">
            <button
              onClick={() => setOpenModal(false)}
              className="bg-[#fff] w-[120px] px-4 py-3 rounded-[10px] text-sm  border "
            >
              Close
            </button>
            <button
              onClick={() => callDispatch()}
              className={`${statusTrn[account]?.bt} rounded-[10px]  w-[120px]`}
            >
              {patchJobStatusLoading
                ? "Updating..."
                : statusTrn[account]?.button}
            </button>
          </div>
        </Box>
      </Modal>

      <div
        className={
          "w-full h-full relative "
          // + poppins.className
        }
      >
        <div className="w-full">
          <div className="flex w-full pl-14 justify-between items-start">
            <h2 className="text-2xl w-1/2 mt-20 font-semibold text-secondary">
              {data?.jobTitle}
            </h2>
            <div className="flex items-center gap-3">
              <div>
                <div className="text-[8px] font-medium mb-4">JOB STATUS</div>

                <Select
                  sx={statusStyles}
                  value={account}
                  onChange={(e) => {
                    handleSelect(e.target.value);
                  }}
                >
                  <MenuItem value={"open"}>Active</MenuItem>
                  <MenuItem value={"suspended"}>Suspend</MenuItem>
                </Select>
              </div>
            </div>
            <div
              onClick={() => setCurrJob(null)}
              className="w-12 h-12 rounded-full hover:bg-[#145DEB42] px-2 py-3 cursor-pointer font-semibold text-center bg-[#145DEB1A] text-primary"
            >
              &#10005;
            </div>
          </div>
          <div className="flex pl-14 items-center gap-2 mt-6 text-[12px]">
            Posted by {data?.user?.first_name?.toUpperCase()} on{" "}
            <span className="text-primary">
              {moment(data?.created_on).format("DD MMMM YYYY")}
            </span>
          </div>

          <div className="border-b border-[#E5E7EB] pl-14 py-10">
            <h2 className="text-sm text-gray-400">Job description</h2>
            <p className="my-3 text-[16px]">{data?.jobDescription}</p>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
              I am looking for
            </h2>

            <div className="flex mt-6 mb-6 gap-6">
              {data?.lookingFor?.map((item, index) => (
                <div
                  className="rounded-[50px] border border-[#366CFE]  py-[10px] px-[15px] text-[#366CFE] text-xs bg-[#EDF2FF]"
                  key={index}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

          {/* <div className="border-b pl-14 border-[#E5E7EB] py-10"> */}
          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
              Location
            </h2>
            <p className="my-3 text-sm text-secondary">{data?.address}</p>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <div className="">
              <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
                Start date / time
              </h2>
              {data?.startDateTime?.map((item, index) => (
                <div className="flex mt-6 mb-6" key={index}>
                  <div>
                    <span>Date</span>
                    <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                      <p className="pt-[8px] mr-[30px] ml-[5px]">
                        {moment(item.date).format("DD/MM/YYYY")}
                      </p>
                      <img
                        width={500}
                        height={500}
                        src={calendar}
                        className="w-10 h-10"
                        alt="img1"
                      />
                    </div>
                  </div>
                  <div className="mr-6 h-[50px] w-[40px] border-b border-[#89909D]"></div>
                  <div>
                    <span>From time</span>
                    <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                      <p className="pt-[8px] mr-[40px] ml-[5px]">
                        {moment(item.fromTime).format("hh:mm a")}
                      </p>
                      <img
                        width={500}
                        height={500}
                        src={clock}
                        className="w-10 h-10"
                        alt="img1"
                      />
                    </div>
                  </div>
                  <div>
                    <span>To time</span>
                    <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                      <p className="pt-[8px] mr-[40px] ml-[5px]">
                        {moment(item.toTime).format("hh:mm a")}
                      </p>
                      <img
                        width={500}
                        height={500}
                        className="w-10 h-10"
                        src={clock}
                        alt="img1"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <div className="w-1/3">
              <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
                Budget
              </h2>
              <p className="text-gray-400 pt-4">£{data?.budget} x hr</p>
            </div>
          </div>

          <div className="pl-14 border-[#E5E7EB] w-full mb-[50px]">
            <div className="flex flex-row pr-12 pt-10 max-lg:pr-4 items-center ">
              {activeTab?.toString() === "requested" ? (
                <button
                  onClick={() => handler("requested", data)}
                  className={`bg-[#18B234] w-[104px] h-[36px] rounded-[10px] text-sm text-white justify-center flex items-center mr-3`}
                >
                  Confirm
                </button>
              ) : activeTab?.toString() === "jobs" ? (
                <button
                  onClick={() => handler("open", data)}
                  className="bg-primary w-[104px] h-[36px] rounded-[10px] text-sm text-white justify-center flex items-center mr-3"
                >
                  Apply job
                </button>
              ) : activeTab?.toString() === "hire_request" ? (
                <button
                  onClick={() => handler("hire_request", data)}
                  className="bg-primary w-[104px] h-[36px] rounded-[10px] text-sm text-white justify-center flex items-center mr-3"
                >
                  I was Hired
                </button>
              ) : null}
              {/* {activeTab?.toString() !== "hired" && activeTab?.toString() !== "declined" && activeTab?.toString() !== "lost" ? (
              <button
                onClick={() => declinedHandler(data)}
                className="flex cursor-pointer justify-center items-center rounded-[10px] h-[36px] w-[36px] bg-[#FF4C00] mr-3"
              >
                <RiForbid2Line className="text-xl text-white" />
              </button>
            ) : null} */}
            </div>
          </div>
          <div>.</div>
        </div>
      </div>
    </>
  );
};

export default SingleJobPage;
