import React, { useState } from "react";
import LoginDetailsTable from "../users/components/LoginDetailsTable";
import { Drawer } from "@mui/material";
import moment from "moment";
import { Close } from "@mui/icons-material";

const LoginDetails = () => {
  const [currUser, setCurrUser] = useState(null);

  return (
    <>
      <Drawer
        anchor={"right"}
        sx={{
          width: "70%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "70%",
            boxSizing: "border-box",
          },
        }}
        open={currUser !== null}
        onClose={() => setCurrUser(null)}
        className="dashboard"
      >
        {currUser !== null ? (
          <div className="bg-white rounded-xl w-full p-10">
            <div className="flex items-center justify-end w-full">
              <div
                onClick={() => setCurrUser(null)}
                className="w-12 h-12 rounded-full hover:bg-[#145DEB42] px-2 py-3 cursor-pointer font-semibold text-center bg-[#145DEB1A] text-primary"
              >
                <Close />
              </div>
            </div>
            <div className="flex justify-between mt-12 mb-6 items-center">
              <div className="flex items-center gap-2">
                <div>
                  <h2 className="text-lg text-black font-semibold">
                    {currUser.name} ({currUser.role})
                  </h2>
                  <p className="text-gray-400 text-xs">
                    {moment(currUser.updated_at).format("dddd, MMMM Do YYYY")}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full pt-10  border-t border-gray-200">
              <p className="text-lg underline font-medium mb-8">
                Personal details
              </p>
              <div className="flex gap-10">
                <div className="mb-5">
                  <p className="text-gray-400 text-sm font-normal">Email</p>
                  <div className="font-semibold"> {currUser.email}</div>
                </div>
                <div>
                  <p className="text-gray-400 text-sm font-normal">Phone </p>
                  <div className="font-semibold">{currUser.phone}</div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Drawer>
      <div className="w-full min-h-screen bg-dull py-6 md:px-10 px-2">
        <LoginDetailsTable setCurrUser={setCurrUser} />
      </div>
    </>
  );
};

export default LoginDetails;
