import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUploadedJobBannersAction,
  loginDetailsAction,
} from "../../../../redux/subadmin/action";
import moment from "moment";
import ListLoader from "../../../loader/listLoader";
import Pagination from "../../../common/Pagination";
import { MdOutlineDelete } from "react-icons/md";
import toast from "react-hot-toast";
import { CiEdit } from "react-icons/ci";

const PAGE_SIZE = 10;

const PromotedJobsTable = ({
  setCurrUser,
  setDrawerOpen,
  setPromotedJobDetails,
  handlePromotedJobEdit,
}) => {
  const { token } = useSelector((state) => state.Authsection);
  const dispatch = useDispatch();
  const { getUploadedJobBanner, getUploadedJobBannerLoading } = useSelector(
    (state) => state.SubadminSection
  );
  const [totalResult, setTotalResult] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedBannerId, setSelectedBannerId] = useState(null);

  useEffect(() => {
    dispatch(
      loginDetailsAction({
        limit: limit,
        page: skip,
      })
    );
  }, [skip]);

  useEffect(() => {
    if (totalResult > 0) {
      setTotalPages(Math.ceil(totalResult / PAGE_SIZE));
    }
  }, [totalResult]);

  useEffect(() => {
    if (getUploadedJobBanner?.data?.count?.total > 0) {
      setTotalResult(getUploadedJobBanner?.data?.count?.total);
    }
  }, [getUploadedJobBanner]);

  useEffect(() => {
    dispatch(getUploadedJobBannersAction({ limit: limit, page: skip }));
  }, [dispatch]);

  const handlePageChange = useCallback((page) => {
    setSkip(page);
  }, []);

  const getPageNumbers = useCallback(() => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }, [totalPages]);

  const handleDelete = (id) => {
    setSelectedBannerId(id);
    setOpen(true);
  };

  const handleEdit = (item) => {
    const payload = {
      promotion_id: item.promotion_id,
      job_title: item.job_details[0].title,
      start_date: item.start_time,
      end_date: item.end_time,
      banner_url: item.banner_url,
    };

    handlePromotedJobEdit(payload);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedBannerId(null);
  };

  const deletePromotedBanner = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/subadmin/jobs/promotion`,
        {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ id: id }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      toast.success(data.message);
      handleClose();
      dispatch(getUploadedJobBannersAction({ limit: limit, page: skip }));
    } catch (error) {
      console.error("There was a problem with the delete request", error);
    }
  };

  return (
    <>
      <TableContainer className={"mt-5 rounded-xl"} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className="font-semibold md:text-base text-xs"
              ></TableCell>
              <TableCell
                align="center"
                className="font-semibold md:text-base text-xs"
              >
                Title
              </TableCell>
              <TableCell
                align="center"
                className="font-semibold md:text-base text-xs"
              >
                Promoted on
              </TableCell>
              <TableCell
                align="center"
                className="font-semibold md:text-base text-xs"
              >
                Started on
              </TableCell>
              <TableCell
                align="center"
                className="font-semibold md:text-base text-xs"
              >
                Ends on
              </TableCell>
              <TableCell
                align="center"
                className="font-semibold md:text-base text-xs"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getUploadedJobBannerLoading ? (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell colSpan={7} align="center">
                  <ListLoader />
                </TableCell>
              </TableRow>
            ) : getUploadedJobBanner?.data?.promotions?.length > 0 ? (
              getUploadedJobBanner?.data?.promotions.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center"></TableCell>
                  <TableCell align="center" className="md:text-base text-xs">
                    <button
                      onClick={() => {
                        setDrawerOpen(true);
                        setPromotedJobDetails(row.job_details[0]);
                      }}
                    >
                      {row.job_details[0].title}
                    </button>
                  </TableCell>
                  <TableCell align="center" className="md:text-base text-xs">
                    {moment(row.created_on).format("ddd, MMM Do YYYY")}
                  </TableCell>
                  <TableCell align="center" className="md:text-base text-xs">
                    {moment(row.start_time).format("ddd, MMM Do YYYY")}
                  </TableCell>
                  <TableCell align="center" className="md:text-base text-xs">
                    {moment(row.end_time).format("ddd, MMM Do YYYY")}
                  </TableCell>
                  <TableCell align="center" className="md:text-base text-xs">
                    {!row.deleted_flag ? (
                      <div className="flex gap-2 items-center justify-center">
                        <button onClick={() => handleEdit(row)}>
                          <CiEdit size={25} color="#007aff" />
                        </button>
                        <button onClick={() => handleDelete(row.promotion_id)}>
                          <MdOutlineDelete size={25} color="red" />
                        </button>
                      </div>
                    ) : (
                      <p style={{ color: "red" }}>Deleted</p>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell colSpan={7} align="center">
                  No data yet.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 0 && (
        <div className="pagination_box">
          <Pagination
            currentPage={skip}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            getPageNumbers={getPageNumbers}
          />
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this promoted job banner?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={() => deletePromotedBanner(selectedBannerId)}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PromotedJobsTable;
