//AUTH
export const POST_LOGIN = "POST_LOGIN";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_ERROR = "POST_LOGIN_ERROR";
export const POST_FORGET_PASSWORD = "POST_FORGET_PASSWORD";
export const POST_FORGET_PASSWORD_SUCCESS = "POST_FORGET_PASSWORD_SUCCESS";
export const POST_FORGET_PASSWORD_ERROR = "POST_FORGET_PASSWORD_ERROR";
export const POST_REGISTER = "POST_REGISTER";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_ERROR = "POST_REGISTER_ERROR";
export const GET_LOCATION = "GET_LOCATION";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_ERROR = "GET_LOCATION_ERROR";
export const PATCH_RESET_PASSWORD = "PATCH_RESET_PASSWORD";
export const PATCH_RESET_PASSWORD_SUCCESS = "PATCH_RESET_PASSWORD_SUCCESS";
export const PATCH_RESET_PASSWORD_ERROR = "PATCH_RESET_PASSWORD_ERROR";
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const VERIFY_ACCOUNT_ERROR = "VERIFY_ACCOUNT_ERROR";
//PROFILE
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";
export const SET_NAME = "SET_NAME";
export const GET_NAME = "GET_NAME";
export const DELETE_NAME = "DELETE_NAME";
export const POST_TRADER_REGISTER = "POST_TRADER_REGISTER";
export const POST_TRADER_REGISTER_SUCCESS = "POST_TRADER_REGISTER_SUCCESS";
export const POST_TRADER_REGISTER_ERROR = "POST_TRADER_REGISTER_ERROR";
export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";
export const UPDATE_PROFILE_IMAGE_SUCCESS = "UPDATE_PROFILE_IMAGE_SUCCESS";
export const UPDATE_PROFILE_IMAGE_ERROR = "UPDATE_PROFILE_IMAGE_ERROR";

// token
export const SET_TOKEN = "SET_TOKEN";
export const GET_TOKEN = "GET_TOKEN";
export const CLEAR_TOKEN = "CLEAR_TOKEN";
export const SET_ROLE = "SET_ROLE";
export const GET_ROLE = "GET_ROLE";
export const CLEAR_ROLE = "CLEAR_ROLE";

//guard
export const GET_LICENCE = "GET_LICENCE";
export const GET_LICENCE_SUCCESS = "GET_LICENCE_SUCCESS";
export const GET_LICENCE_ERROR = "GET_LICENCE_ERROR";
export const GET_RATING = "GET_RATING";
export const GET_RATING_SUCCESS = "GET_RATING_SUCCESS";
export const GET_RATING_ERROR = "GET_RATING_ERROR";
export const GET_JOBS = "GET_JOBS";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_ERROR = "GET_JOBS_ERROR";
export const GET_JOBLIST = "GET_JOBLIST";
export const GET_JOBLIST_SUCCESS = "GET_JOBLIST_SUCCESS";
export const GET_JOBLIST_ERROR = "GET_JOBLIST_ERROR";
export const GET_GUARD_DASHBOARD = "GET_GUARD_DASHBOARD";
export const GET_GUARD_DASHBOARD_SUCCESS = "GET_GUARD_DASHBOARD_SUCCESS";
export const GET_GUARD_DASHBOARD_ERROR = "GET_GUARD_DASHBOARD_ERROR";
export const GET_JOBS_ANALYSIS = "GET_JOBS_ANALYSIS";
export const GET_JOBS_ANALYSIS_SUCCESS = "GET_JOBS_ANALYSIS_SUCCESS";
export const GET_JOBS_ANALYSIS_ERROR = "GET_JOBS_ANALYSIS_ERROR";
export const GET_GUARD_PROFILE = "GET_GUARD_PROFILE";
export const GET_GUARD_PROFILE_SUCCESS = "GET_GUARD_PROFILE_SUCCESS";
export const GET_GUARD_PROFILE_ERROR = "GET_GUARD_PROFILE_ERROR";
export const POST_JOB_APPLY = "POST_JOB_APPLY";
export const POST_JOB_APPLY_SUCCESS = "POST_JOB_APPLY_SUCCESS";
export const POST_JOB_APPLY_ERROR = "POST_JOB_APPLY_ERROR";
export const PATCH_JOB_STATUS = "PATCH_JOB_STATUS";
export const PATCH_JOB_STATUS_SUCCESS = "PATCH_JOB_STATUS_SUCCESS";
export const PATCH_JOB_STATUS_ERROR = "PATCH_JOB_STATUS_ERROR";
export const POST_LICENCE = "POST_LICENCE";
export const POST_LICENCE_SUCCESS = "POST_LICENCE_SUCCESS";
export const POST_LICENCE_ERROR = "POST_LICENCE_ERROR";
export const PUT_PROFILE = "PUT_PROFILE";
export const PUT_PROFILE_SUCCESS = "PUT_PROFILE_SUCCESS";
export const PUT_PROFILE_ERROR = "PUT_PROFILE_ERROR";

export const PUT_LICENCE = "PUT_LICENCE";
export const PUT_LICENCE_SUCCESS = "PUT_LICENCE_SUCCESS";
export const PUT_LICENCE_ERROR = "PUT_LICENCE_ERROR";

export const PATCH_LICENSE = "PATCH_LICENSE";
export const PATCH_LICENSE_SUCCESS = "PATCH_LICENSE_SUCCESS";
export const PATCH_LICENSE_ERROR = "PATCH_LICENSE_ERROR";

export const INVITE_CO_WORKERS = "INVITE_CO_WORKERS";
export const INVITE_CO_WORKERS_SUCCSESS = "INVITE_CO_WORKERS_SUCCSESS";
export const INVITE_CO_WORKERS_ERROR = "INVITE_CO_WORKERS_ERROR";

//CLIENT

export const GET_CLIENT_JOBS = "GET_CLIENT_JOBS";
export const GET_CLIENT_JOBS_SUCCESS = "GET_CLIENT_JOBS_SUCCESS";
export const GET_CLIENT_JOBS_ERROR = "GET_CLIENT_JOBS_ERROR";
export const GET_CLIENT_POSTED_JOBS = "GET_CLIENT_POSTED_JOBS";
export const GET_CLIENT_POSTED_JOBS_SUCCESS = "GET_CLIENT_POSTED_JOBS_SUCCESS";
export const GET_CLIENT_POSTED_JOBS_ERROR = "GET_CLIENT_POSTED_JOBS_ERROR";
export const GET_FIND_TRADERS = "GET_FIND_TRADERS";
export const GET_FIND_TRADERS_SUCCESS = "GET_FIND_TRADERS_SUCCESS";
export const GET_FIND_TRADERS_ERROR = "GET_FIND_TRADERS_ERROR";
export const GET_CLIENT_JOBS_LIST = "GET_CLIENT_JOBS_LIST";
export const GET_CLIENT_JOBS_LIST_SUCCESS = "GET_CLIENT_JOBS_LIST_SUCCESS";
export const GET_CLIENT_JOBS_LIST_ERROR = "GET_CLIENT_JOBS_LIST_ERROR";
export const GET_CLIENT_JOBS_ANALYSIS = "GET_CLIENT_JOBS_ANALYSIS";
export const GET_CLIENT_JOBS_ANALYSIS_SUCCESS =
  "GET_CLIENT_JOBS_ANALYSIS_SUCCESS";
export const GET_CLIENT_JOBS_ANALYSIS_ERROR = "GET_CLIENT_JOBS_ANALYSIS_ERROR";
export const GET_CLIENT_REVIEW = "GET_CLIENT_REVIEW";
export const GET_CLIENT_REVIEW_SUCCESS = "GET_CLIENT_REVIEW_SUCCESS";
export const GET_CLIENT_REVIEW_ERROR = "GET_CLIENT_REVIEW_ERROR";
export const POST_JOB = "POST_JOB";
export const POST_JOB_SUCCESS = "POST_JOB_SUCCESS";
export const POST_JOB_ERROR = "POST_JOB_ERROR";
export const POST_JOB_TOKEN = "POST_JOB_TOKEN_LIST";
export const POST_JOB_TOKEN_SUCCESS = "POST_JOB_TOKEN_SUCCESS";
export const POST_JOB_TOKEN_ERROR = "POST_JOB_TOKEN_ERROR";
export const POST_REVIEW = "POST_REVIEW_LIST";
export const POST_REVIEW_SUCCESS = "POST_REVIEW_SUCCESS";
export const POST_REVIEW_ERROR = "POST_REVIEW_ERROR";
export const PATCH_CLIENT_JOB_STATUS = "PATCH_CLIENT_JOB_STATUS";
export const PATCH_CLIENT_JOB_STATUS_SUCCESS =
  "PATCH_CLIENT_JOB_STATUS_SUCCESS";
export const PATCH_CLIENT_JOB_STATUS_ERROR = "PATCH_CLIENT_JOB_STATUS_ERROR";

export const GET_CLIENT_DASHBOARD = "GET_CLIENT_DASHBOARD";
export const GET_CLIENT_DASHBOARD_SUCCESS = "GET_CLIENT_DASHBOARD_SUCCESS";
export const GET_CLIENT_DASHBOARD_ERROR = "GET_CLIENT_DASHBOARD_ERROR";

export const GET_CLIENT_DASHBOARD_CHART = "GET_CLIENT_DASHBOARD_CHART";
export const GET_CLIENT_DASHBOARD_CHART_SUCCESS =
  "GET_CLIENT_DASHBOARD_CHART_SUCCESS";
export const GET_CLIENT_DASHBOARD_CHART_ERROR =
  "GET_CLIENT_DASHBOARD_CHART_ERROR";

//admin
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_ERROR = "GET_DASHBOARD_ERROR";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_CHARTS = "GET_DASHBOARD_CHARTS";
export const GET_DASHBOARD_CHARTS_ERROR = "GET_DASHBOARD_CHARTS_ERROR";
export const GET_DASHBOARD_CHARTS_SUCCESS = "GET_DASHBOARD_CHARTS_SUCCESS";
export const GET_TRADE_MEMBERS = "GET_TRADE_MEMBERS";
export const GET_TRADE_MEMBERS_ERROR = "GET_TRADE_MEMBERS_ERROR";
export const GET_TRADE_MEMBERS_SUCCESS = "GET_TRADE_MEMBERS_SUCCESS";
export const GET_USERS = "GET_USERS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_ADMIN_JOBS = "GET_ADMIN_JOBS";
export const GET_ADMIN_JOBS_ERROR = "GET_ADMIN_JOBS_ERROR";
export const GET_ADMIN_JOBS_SUCCESS = "GET_ADMIN_JOBS_SUCCESS";
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_REVIEWS_ERROR = "GET_REVIEWS_ERROR";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const PATCH_TRADE_MEMBERS = "PATCH_TRADE_MEMBERS";
export const PATCH_TRADE_MEMBERS_ERROR = "PATCH_TRADE_MEMBERS_ERROR";
export const PATCH_TRADE_MEMBERS_SUCCESS = "PATCH_TRADE_MEMBERS_SUCCESS";
export const DELETE_USERS = "DELETE_USERS";
export const DELETE_USERS_ERROR = "DELETE_USERS_ERROR";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const PATCH_JOBS = "PATCH_JOBS";
export const PATCH_JOBS_ERROR = "PATCH_JOBS_ERROR";
export const PATCH_JOBS_SUCCESS = "PATCH_JOBS_SUCCESS";
export const TRADER_UPDATED = "TRADER_UPDATED";

export const PATCH_CLIENT = "PATCH_CLIENT";
export const PATCH_CLIENT_SUCCESS = "PATCH_CLIENT_SUCCESS";
export const PATCH_CLIENT_ERROR = "PATCH_CLIENT_ERROR";
export const CLIENT_UPDATED = "CLIENT_UPDATED";
export const PATCH_REVIEWS = "PATCH_REVIEWS";
export const PATCH_REVIEWS_SUCCESS = "PATCH_REVIEWS_SUCCESS";
export const PATCH_REVIEWS_ERROR = "PATCH_REVIEWS_ERROR";

export const JOB_UPDATED = "JOB_UPDATED";
export const LOGOUT = "LOGOUT";

export const GET_TRADE_MEMBERS_BETWEEN_DATES =
  "GET_TRADE_MEMBERS_BETWEEN_DATES";
export const GET_TRADE_MEMBERS_BETWEEN_DATES_ERROR =
  "GET_TRADE_MEMBERS_BETWEEN_DATES_ERROR";
export const GET_TRADE_MEMBERS_BETWEEN_DATES_SUCCESS =
  "GET_TRADE_MEMBERS_BETWEEN_DATES_SUCCESS";

export const GET_USERS_BETWEEN_DATES = "GET_USERS_BETWEEN_DATES";
export const GET_USERS_BETWEEN_DATES_ERROR = "GET_USERS_BETWEEN_DATES_ERROR";
export const GET_USERS_BETWEEN_DATES_SUCCESS =
  "GET_USERS_BETWEEN_DATES_SUCCESS";

export const GET_JOBS_BETWEEN_DATES = "GET_JOBS_BETWEEN_DATES";
export const GET_JOBS_BETWEEN_DATES_ERROR = "GET_JOBS_BETWEEN_DATES_ERROR";
export const GET_JOBS_BETWEEN_DATES_SUCCESS = "GET_JOBS_BETWEEN_DATES_SUCCESS";

export const GET_FILTERED_TRADE_MEMBERS = "GET_FILTERED_TRADE_MEMBERS";
export const GET_FILTERED_TRADE_MEMBERS_ERROR =
  "GET_FILTERED_TRADE_MEMBERS_ERROR";
export const GET_FILTERED_TRADE_MEMBERS_SUCCESS =
  "GET_FILTERED_TRADE_MEMBERS_SUCCESS";

export const REFER_GUARD = "REFER_GUARD";
export const REFER_GUARD_SUCCESS = "REFER_GUARD_SUCCESS";
export const REFER_GUARD_ERROR = "REFER_GUARD_ERROR";

export const REFER_CO_WORKERS = "REFER_CO_WORKERS";
export const REFER_CO_WORKERS_SUCCSESS = "REFER_CO_WORKERS_SUCCSESS";
export const REFER_CO_WORKERS_ERROR = "REFER_CO_WORKERS_ERROR";

export const REFERED_GUARD_DETAILS = "REFERED_GUARD_DETAILS";
export const REFERED_GUARD_DETAILS_SUCCSESS = "REFERED_GUARD_DETAILS_SUCCSESS";
export const REFERED_GUARD_DETAILS_ERROR = "REFERED_GUARD_DETAILS_ERROR";

export const UPDATE_CLIENT_PASSWORD = "UPDATE_CLIENT_PASSWORD";
export const UPDATE_CLIENT_PASSWORD_SUCCESS = "UPDATE_CLIENT_PASSWORD_SUCCESS";
export const UPDATE_CLIENT_PASSWORD_ERROR = "UPDATE_CLIENT_PASSWORD_ERROR";

export const UPDATE_ADMIN_PASSWORD = "UPDATE_ADMIN_PASSWORD";
export const UPDATE_ADMIN_PASSWORD_SUCCESS = "UPDATE_ADMIN_PASSWORD_SUCCESS";
export const UPDATE_ADMIN_PASSWORD_ERROR = "UPDATE_ADMIN_PASSWORD_ERROR";

export const UPDATE_CLIENT_PROFILE = "UPDATE_CLIENT_PROFILE";
export const UPDATE_CLIENT_PROFILE_SUCCESS = "UPDATE_CLIENT_PROFILE_SUCCESS";
export const UPDATE_CLIENT_PROFILE_ERROR = "UPDATE_CLIENT_PROFILE_ERROR";

export const UPDATE_PASSOWRD = "UPDATE_PASSOWRD";
export const UPDATE_PASSOWRD_SUCCESS = "UPDATE_PASSOWRD_SUCCESS";
export const UPDATE_PASSOWRD_ERROR = "UPDATE_PASSOWRD_ERROR";

export const SUBSCRIPTION_PAYMENT = "SUBSCRIPTION_PAYMENT";
export const SUBSCRIPTION_PAYMENT_SUCCESS = "SUBSCRIPTION_PAYMENT_SUCCESS";
export const SUBSCRIPTION_PAYMENT_ERROR = "SUBSCRIPTION_PAYMENT_ERROR";

export const PAYMENT_DATA = "PAYMENT_DATA";
export const PAYMENT_DATA_SUCCESS = "PAYMENT_DATA_SUCCESS";
export const PAYMENT_DATA_ERROR = "PAYMENT_DATA_ERROR";

export const MESSAGE_DATA = "MESSAGE_DATA";
export const MESSAGE_DATA_SUCCESS = "MESSAGE_DATA_SUCCESS";
export const MESSAGE_DATA_ERROR = "MESSAGE_DATA_ERROR";

export const SEND_MESSAGE_DATA = "SEND_MESSAGE_DATA";
export const SEND_MESSAGE_DATA_SUCCESS = "SEND_MESSAGE_DATA_SUCCESS";
export const SEND_MESSAGE_DATA_ERROR = "SEND_MESSAGE_DATA_ERROR";

export const LOGIN_DETAILS_DATA = "LOGIN_DETAILS_DATA";
export const LOGIN_DETAILS_DATA_SUCCESS = "LOGIN_DETAILS_DATA_SUCCESS";
export const LOGIN_DETAILS_DATA_ERROR = "LOGIN_DETAILS_DATA_ERROR";

export const UPLOAD_JOB_BANNER = "UPLOAD_JOB_BANNER";
export const UPLOAD_JOB_BANNER_SUCCESS = "UPLOAD_JOB_BANNER_SUCCESS";
export const UPLOAD_JOB_BANNER_ERROR = "UPLOAD_JOB_BANNER_ERROR";

export const GET_UPLOAD_JOB_BANNER = "GET_UPLOAD_JOB_BANNER";
export const GET_UPLOAD_JOB_BANNER_SUCCESS = "GET_UPLOAD_JOB_BANNER_SUCCESS";
export const GET_UPLOAD_JOB_BANNER_ERROR = "GET_UPLOAD_JOB_BANNER_ERROR";

export const UPDATE_JOB_BANNER = "UPDATE_JOB_BANNER";
export const UPDATE_JOB_BANNER_SUCCESS = "UPDATE_JOB_BANNER_SUCCESS";
export const UPDATE_JOB_BANNER_ERROR = "UPDATE_JOB_BANNER_ERROR";

export const ADD_SUB_ADMIN = "ADD_SUB_ADMIN";
export const ADD_SUB_ADMIN_SUCCESS = "ADD_SUB_ADMIN_SUCCESS";
export const ADD_SUB_ADMIN_ERROR = "ADD_SUB_ADMIN_ERROR";

export const UPDATE_SUB_ADMIN = "UPDATE_SUB_ADMIN";
export const UPDATE_SUB_ADMIN_SUCCESS = "UPDATE_SUB_ADMIN_SUCCESS";
export const UPDATE_SUB_ADMIN_ERROR = "UPDATE_SUB_ADMIN_ERROR";

export const GET_SUB_ADMIN = "GET_SUB_ADMIN";
export const GET_SUB_ADMIN_SUCCESS = "GET_SUB_ADMIN_SUCCESS";
export const GET_SUB_ADMIN_ERROR = "GET_SUB_ADMIN_ERROR";
// export * from "./action";
