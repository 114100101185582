import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Layout from "../Layout/layout";
import ChatComponent from "../ai-chat-bot/chat-app/ChatComponent";

export const ProtectedRoute = ({ role }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/sign-in");
    } else if (isRole(role)) {
      navigate(-1);
    }
  }, [navigate]);

  return (
    <Layout>
      <Outlet />
      {/* <ChatComponent /> */}
    </Layout>
  );
};
function isAuthenticated() {
  return localStorage.getItem("token") !== null;
}
function isRole(role) {
  return localStorage.getItem("role") !== role;
}
