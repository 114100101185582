import React from "react";
import discover_1 from "../../../../assets/images/about-us/discover_1.svg";
import discover_2 from "../../../../assets/images/about-us/discover_2.svg";
import discover_3 from "../../../../assets/images/about-us/discover_3.svg";
import discover_4 from "../../../../assets/images/about-us/discover_4.svg";

import { motion } from "framer-motion";

const _initialLeft = { x: "-40%", opacity: 0 };
const _whileInViewLeft = {
  x: 0,
  opacity: 1,
  transition: {
    delay: 0.2,
    duration: 0.8,
  },
};

const _initialRight = { x: "100%", opacity: 0 };
const _whileInViewRight = {
  x: 0,
  opacity: 1,
  transition: {
    delay: 0.2,
    duration: 0.8,
  },
};

const _viewport = { once: true };

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

const focus = [
  {
    id: "1",
    icon: discover_1,
    title: "Trusted Professionals",
    description:
      "Gain access to certified security experts who prioritize your safety through rigorous screening processes.",
  },

  {
    id: "2",
    icon: discover_2,
    title: "Long-Term Partnerships",
    description:
      "Establish enduring security alliances for tailored protection and consistent support.",
  },
  {
    id: "3",
    icon: discover_3,
    title: "Peace of Mind",
    description:
      "Trained professionals ensure swift response to security threats, offering peace of mind with their vigilance and readiness.",
  },
  {
    id: "4",
    icon: discover_4,
    title: "Tailored Solutions",
    description:
      "Tailor your security needs with flexibility. Choose from diverse security personnel to match your unique situation.",
  },
];

function DiscoverCraftsmen() {
  return (
    <div
      className={`w-full  px-[20px] py-[32px] sm:py-[50px] my-[0px] xl:my-[70px] `}
    >
      <div className="max-w-[1340px] mx-auto">
        <div className="flex flex-col md:flex-row gap-[20px] sm:gap-[32px] md:gap-[86px] items-start md:items-center">
          <motion.div
            className=""
            initial={_initialLeft}
            whileInView={{
              ..._whileInViewLeft,
              transition: {
                duration: 0.8,
              },
            }}
            viewport={_viewport}
          >
            <span className="w-full text-[15px] text-[#145DEB] ">
              FOR CLIENTS
            </span>
            <h2 className="text-[#1A202C] font-[600] max-w-[322px] text-[40px] leading-[52px] mt-[16px]">
              Discover skilled Craftspeople easily!
            </h2>
          </motion.div>

          <motion.p
            viewport={_viewport}
            initial={_initialRight}
            whileInView={{
              ..._whileInViewRight,
              transition: {
                duration: 0.8,
                delay: 0.3,
              },
            }}
            className="flex-1 text-[#596780] font-[400] max-w-[452px] leading-[27px] text-[18px] "
          >
            With igardu, you gain exclusive access to highly skilled and vetted
            craftsmen across the UK. It's budget-friendly and hiring is
            seamless.
          </motion.p>
        </div>

        <div className=" grid grid-cols-1 sm:grid-cols-2 xl:flex gap-[24px] mt-[32px] md:mt-[55px] flex-wrap justify-center ">
          {focus.map((item, index) => {
            return (
              <motion.div
                initial={_inital}
                whileInView={{
                  ..._whileInView,
                  transition: {
                    duration: 0.8,
                    delay: 0.2 * index,
                  },
                }}
                viewport={_viewport}
                key={item?.id}
                className="bg-[#F5FAFF] flex-1 py-[32px] md:py-[20px] xl:py-[32px] px-[20px] xl:px-[32px] grow-0 shrink-0 min-w-[clamp(200px,calc(317/1440*100vw),317px)] h-auto md:h-[300px] lg:h-[clamp(200px,calc(296/1440*100vw),296px)] rounded-[10px] flex flex-col items-center justify-center"
              >
                <img
                  src={item?.icon}
                  alt="icon_desc"
                  className="h-[clamp(40px,calc(60/1440*100vw),60px)] aspect-square mb-[16px] xl:mb-[24px]"
                />

                <h3 className="px-[10px] xl:px-[20px] text-[20px] xl:text-[22px] text-center leading-[27px] font-[600] text-[#1E293B] mb-[8px]">
                  {item?.title}
                </h3>

                <p className="   text-center  max-w-[452px] text-[14px] md:text-[13px] xl:text-[14px]  leading-[19px] xl:leading-[22px] font-[300] text-[#374151]">
                  {item?.description}
                </p>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DiscoverCraftsmen;
