import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { poppins } from '@/components/fonts';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Modal } from "@mui/material";
import toast from "react-hot-toast";
import {
  getReviewsAction,
  patchReviewAction,
} from "../../../../redux/admin/action";
import ListLoader from "../../../loader/listLoader";

export default function ReviewsTable({ setCurrReview, reviews }) {
  const [openModal, setOpenModal] = useState(false);
  const [review, setReview] = useState({});
  const [initial, setInitial] = useState(false);
  const dispatch = useDispatch();
  const { getReviewsLoading, getReviews, patchReviewLoading, patchReview } =
    useSelector((state) => state.SubadminSection);

  const openDeleteConfirmModal = (data) => {
    setOpenModal(true);
    setReview(data);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20%",
    borderRadius: "20px",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
  };

  const statusTrn = {
    closed: {
      button: "Delete",
      title: "Delete Review",
      description: `Are you sure you want to delete this review?`,
      bg: "bg-[#FEE4E2]",
      color: "#D92D20",
      bt: "bg-[#D92D20] text-white",
    },
  };

  useEffect(() => {
    if (patchReview.success && initial === true) {
      toast.success(patchReview.message);
      setOpenModal(false);
      setInitial(false);
      dispatch(getReviewsAction());
    } else if (patchReview.success === false && initial === true) {
      toast.error(patchReview.message);
      setInitial(false);
      setOpenModal(false);
    }
  }, [dispatch, patchReview]);

  const callDispatch = () => {
    dispatch(
      patchReviewAction({
        id: review?.id,
      })
    );
    setInitial(true);
    // window.location.reload(false)
  };

  const infoIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={statusTrn["closed"]?.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <TableContainer
      // sx={{fontFamily: poppins.style.fontFamily}}
      className={"mt-5 rounded-xl"}
      component={Paper}
    >
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <div
            className={`${statusTrn["closed"]?.bg} h-[40px] w-[40px] rounded-full flex justify-center items-center mb-4`}
          >
            {infoIcon}
          </div>
          <Typography
            // id="v-title"
            variant="h6"
            component="h2"
            className="font-black"
          >
            {statusTrn["closed"]?.title}
          </Typography>
          <Typography sx={{ my: 2 }}>
            {statusTrn["closed"]?.description}
          </Typography>
          <div className="flex justify-between">
            <button
              onClick={() => setOpenModal(false)}
              className="bg-[#fff] w-[120px] px-4 py-3 rounded-[10px] text-sm  border "
            >
              Close
            </button>
            <button
              onClick={() => callDispatch()}
              className={`${statusTrn["closed"]?.bt} rounded-[10px]  w-[120px]`}
            >
              {patchReviewLoading ? "deleting..." : statusTrn["closed"]?.button}
            </button>
          </div>
        </Box>
      </Modal>
      <Table
        // sx={{ fontFamily: poppins.style.fontFamily }}
        aria-label="simple table"
        className=""
      >
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              //   sx={{
              //     fontFamily: poppins.style.fontFamily,
              //   }}
              className="font-semibold md:text-base text-xs"
            >
              {/* <input type={'checkbox'} /> */}
            </TableCell>
            <TableCell
              align="center"
              //   sx={{
              //     fontFamily: poppins.style.fontFamily,
              //   }}
              className="font-semibold md:text-base text-xs"
            >
              Title
            </TableCell>
            <TableCell
              align="center"
              //   sx={{
              //     fontFamily: poppins.style.fontFamily,
              //   }}
              className="font-semibold md:text-base text-xs"
            >
              Ratings
            </TableCell>
            <TableCell
              align="center"
              //   sx={{
              //     fontFamily: poppins.style.fontFamily,
              //   }}
              className="font-semibold md:text-base text-xs"
            >
              Date
            </TableCell>
            <TableCell
              align="center"
              //   sx={{
              //     fontFamily: poppins.style.fontFamily,
              //   }}
              className="font-semibold md:text-base text-xs"
            >
              By
            </TableCell>
            <TableCell
              align="center"
              //   sx={{
              //     fontFamily: poppins.style.fontFamily,
              //   }}
              className="font-semibold md:text-base text-xs"
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getReviewsLoading ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                colSpan={7}
                align="center"
                // sx={{ fontFamily: poppins.style.fontFamily }}
              >
                <ListLoader />
              </TableCell>
            </TableRow>
          ) : !reviews?.length > 0 ? (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                colSpan={7}
                align="center"
                // sx={{ fontFamily: poppins.style.fontFamily }}
              >
                No data yet.
              </TableCell>
            </TableRow>
          ) : (
            reviews?.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="center"
                  //   sx={{
                  //     fontFamily: poppins.style.fontFamily,
                  //   }}
                >
                  <div className="flex items-center justify-center gap-3 md:text-base text-xs">
                    {/* <input type={'checkbox'} /> */}
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  //   sx={{
                  //     fontFamily: poppins.style.fontFamily,
                  //   }}
                >
                  <div className="flex items-center justify-center gap-3 md:text-base text-xs">
                    {row.title ?? "-"}
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  //   sx={{
                  //     fontFamily: poppins.style.fontFamily,
                  //   }}
                  className="md:text-base text-xs"
                >
                  {row.rating}
                </TableCell>
                <TableCell
                  align="center"
                  //   sx={{
                  //     fontFamily: poppins.style.fontFamily,
                  //   }}
                >
                  <div className="rounded-full md:p-2 md:text-base text-xs cursor-pointer flex items-center w-max mx-auto gap-2 px-4 hover:bg-[#3690fe48]">
                    {moment(row.meta?.created_on).format("DD/MM/YYYY")}
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  //   sx={{
                  //     fontFamily: poppins.style.fontFamily,
                  //   }}
                >
                  <div className="rounded-full md:p-2 md:text-base text-xs cursor-pointer flex items-center w-max mx-auto gap-2 px-4 hover:bg-[#3690fe48]">
                    {row.client?.first_name} {row.client?.last_name}
                  </div>
                </TableCell>

                <TableCell
                  align="center"
                  //   sx={{
                  //     fontFamily: poppins.style.fontFamily,
                  //   }}
                >
                  <div className="rounded-full md:p-2  cursor-pointer flex items-center w-max mx-auto gap-4 px-4 ">
                    <svg
                      onClick={() => setCurrReview(row)}
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_3676_4928)">
                        <path
                          d="M18.4229 7.4564C17.195 5.45665 14.402 2.10156 9.49998 2.10156C4.59798 2.10156 1.80498 5.45665 0.577105 7.4564C0.197523 8.07034 -0.00354004 8.77788 -0.00354004 9.49969C-0.00354004 10.2215 0.197523 10.929 0.577105 11.543C1.80498 13.5427 4.59798 16.8978 9.49998 16.8978C14.402 16.8978 17.195 13.5427 18.4229 11.543C18.8024 10.929 19.0035 10.2215 19.0035 9.49969C19.0035 8.77788 18.8024 8.07034 18.4229 7.4564ZM17.0731 10.7141C16.0186 12.4289 13.6317 15.3145 9.49998 15.3145C5.36827 15.3145 2.9814 12.4289 1.9269 10.7141C1.70138 10.3492 1.58193 9.92867 1.58193 9.49969C1.58193 9.0707 1.70138 8.65019 1.9269 8.28527C2.9814 6.57052 5.36827 3.6849 9.49998 3.6849C13.6317 3.6849 16.0186 6.56735 17.0731 8.28527C17.2986 8.65019 17.418 9.0707 17.418 9.49969C17.418 9.92867 17.2986 10.3492 17.0731 10.7141Z"
                          fill="black"
                        />
                        <path
                          d="M9.50002 5.54297C8.71714 5.54297 7.95183 5.77512 7.30089 6.21007C6.64995 6.64502 6.1426 7.26322 5.843 7.98651C5.5434 8.7098 5.46501 9.50569 5.61775 10.2735C5.77048 11.0414 6.14747 11.7467 6.70106 12.3003C7.25464 12.8538 7.95995 13.2308 8.72779 13.3836C9.49563 13.5363 10.2915 13.4579 11.0148 13.1583C11.7381 12.8587 12.3563 12.3514 12.7913 11.7004C13.2262 11.0495 13.4584 10.2842 13.4584 9.5013C13.4571 8.45187 13.0397 7.44579 12.2976 6.70373C11.5555 5.96167 10.5495 5.54423 9.50002 5.54297ZM9.50002 11.8763C9.03029 11.8763 8.57111 11.737 8.18054 11.476C7.78998 11.2151 7.48557 10.8442 7.30581 10.4102C7.12605 9.9762 7.07902 9.49867 7.17066 9.03796C7.2623 8.57726 7.48849 8.15407 7.82064 7.82192C8.15279 7.48977 8.57598 7.26358 9.03668 7.17194C9.49739 7.0803 9.97492 7.12733 10.4089 7.30709C10.8429 7.48685 11.2138 7.79126 11.4748 8.18182C11.7357 8.57239 11.875 9.03157 11.875 9.5013C11.875 10.1312 11.6248 10.7353 11.1794 11.1807C10.734 11.6261 10.1299 11.8763 9.50002 11.8763Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3676_4928">
                          <rect width="19" height="19" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <svg
                      onClick={() => openDeleteConfirmModal(row)}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3333 5.0013V4.33464C13.3333 3.40121 13.3333 2.9345 13.1517 2.57798C12.9919 2.26438 12.7369 2.00941 12.4233 1.84962C12.0668 1.66797 11.6001 1.66797 10.6667 1.66797H9.33333C8.39991 1.66797 7.9332 1.66797 7.57668 1.84962C7.26308 2.00941 7.00811 2.26438 6.84832 2.57798C6.66667 2.9345 6.66667 3.40121 6.66667 4.33464V5.0013M8.33333 9.58464V13.7513M11.6667 9.58464V13.7513M2.5 5.0013H17.5M15.8333 5.0013V14.3346C15.8333 15.7348 15.8333 16.4348 15.5608 16.9696C15.3212 17.44 14.9387 17.8225 14.4683 18.0622C13.9335 18.3346 13.2335 18.3346 11.8333 18.3346H8.16667C6.76654 18.3346 6.06647 18.3346 5.53169 18.0622C5.06129 17.8225 4.67883 17.44 4.43915 16.9696C4.16667 16.4348 4.16667 15.7348 4.16667 14.3346V5.0013"
                        stroke="black"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
