import React, { useEffect, useState } from "react";
import moment from "moment";
import { GoInfo } from "react-icons/go";
import { RiForbid2Line, RiShareForwardFill } from "react-icons/ri";
import { Box, Modal, setRef } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getReferedGuardsAction,
  inviteCoWorkersAction,
  referCoWorkersAction,
} from "../../../redux/guard/action";
import { IoChatbubblesOutline } from "react-icons/io5";
import GeneralChat from "../../ai-chat-bot/general-chat/GeneralChat";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
const JobList = ({
  item,
  viewJob,
  activeTab,
  handler,
  declinedHandler,
  status,
}) => {
  let job = item;
  const [open, setOpen] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedJobToRefer, setSelectedJobToRefer] = useState(null);
  const [selectedJobForChat, setSelectedJobForChat] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [emailInput, setEmailInput] = useState("");
  const [emails, setEmails] = useState([]);
  const dispatch = useDispatch();
  const { inviteCoWorkers, inviteCoWorkersLoading } = useSelector(
    (state) => state.GuardSection
  );
  const { referCoWorkers, referCoWorkersLoading } = useSelector(
    (state) => state.GuardSection
  );
  const [isInvitationSent, setIsInvitationSent] = useState(false);

  const [referalOpen, setReferalOpen] = useState(false);

  const { getReferedGuards, getReferedGuardsLoading } = useSelector(
    (state) => state.GuardSection
  );

  const [allEmails, setAllEmails] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);

  const [isReferalSent, setIsReferalSent] = useState(false);

  const user_data = localStorage.getItem("user_data");
  const userData = JSON.parse(user_data);

  const shareUrl = "https://example.com";

  const tagsStyle =
    "bg-[#F6F5F5] text-[#5B5B5B] rounded-[10px] px-[30px] py-[5px] mr-4 md:text-base text-xs font-medium  whitespace-nowrap";

  useEffect(() => {
    if (referCoWorkers?.success && !referCoWorkersLoading && isReferalSent) {
      toast.success(referCoWorkers?.message);
      setEmails([]);
      setReferalOpen(false);
      setIsReferalSent(false);
      setSelectedJobToRefer(null);
    } else if (
      !referCoWorkers?.success &&
      !referCoWorkersLoading &&
      isReferalSent
    ) {
      toast.error(referCoWorkers?.message);
      setIsReferalSent(false);
    }
  }, [referCoWorkers, referCoWorkersLoading, isReferalSent]);

  useEffect(() => {
    if (
      inviteCoWorkers?.success &&
      !inviteCoWorkersLoading &&
      isInvitationSent
    ) {
      toast.success(inviteCoWorkers?.message);
      setEmails([]);
      setOpen(false);
      setIsInvitationSent(false);
    } else if (
      !inviteCoWorkers?.success &&
      !inviteCoWorkersLoading &&
      isInvitationSent
    ) {
      toast.error(inviteCoWorkers?.message);
      setIsInvitationSent(false);
    }
  }, [inviteCoWorkers, inviteCoWorkersLoading, isInvitationSent]);

  const handleInvite = (job) => {
    const payload = {
      emails: emails,
      job_id: job.job_id,
      job_location: job.address,
      location_lat: job.lat,
      location_lng: job.lng,
      job_role: job.role,
      job_sector: job.sector,
      job_trades: job.trades,
    };
    console.log(payload);

    dispatch(inviteCoWorkersAction(payload));
    setIsInvitationSent(true);
  };

  const chatStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    height: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 4,
    borderRadius: 5,
    marginHorizontal: 10,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    marginHorizontal: 10,
  };

  // useEffect(() => {
  //   if (allEmails.length > 0) {
  //     setReferalOpen(true);
  //   }
  // }, [allEmails]);

  useEffect(() => {
    if (getReferedGuards) {
      setAllEmails(getReferedGuards?.data);
    }
  }, [getReferedGuards]);

  // useEffect(() => {
  //   if (selectedJobToRefer) {
  //     dispatch(getReferedGuardsAction(selectedJobToRefer?.job_id));
  //   }
  // }, [selectedJobToRefer]);

  const handleRefer = (item) => {
    setSelectedJobToRefer(item);
    dispatch(getReferedGuardsAction(item?.job_id));
    setReferalOpen(true);
  };

  const handleShare = (item) => {
    setIsModalVisible(!isModalVisible);
    const payload = {
      title: item.title,
      posted_by: item.posted_by_first_name + " " + item.posted_by_last_name,
      budget: item.budget,
      description: item.description,
      sector: item.sector,
    };

    // console.log(payload);
  };

  const handleReferGuard = (job) => {
    const payload = {
      job_id: job.job_id,
      emails: emails,
    };
    console.log(payload);

    dispatch(referCoWorkersAction(payload));
    setIsReferalSent(true);
  };

  const handleReferalClose = () => {
    setReferalOpen(false);
    setEmails([]);
    setEmailInput("");
    setFilteredEmails([]);
  };

  const handleOpen = (item) => {
    setOpen(true);
    setSelectedJob(item);
  };

  const handleClose = () => {
    setOpen(false);
    setEmails([]);
    setEmailInput("");
  };

  const handleOpenChat = (item) => {
    setOpenChat(true);
    setSelectedJobForChat(item);
  };

  const handleCloseChat = () => {
    setOpenChat(false);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const newEmail = e.target.value.trim();
      if (newEmail && !emails.includes(newEmail)) {
        if (!validateEmail(newEmail)) {
          toast.error("Invalid email ID");
        } else {
          setEmails([...emails, newEmail]);
          setEmailInput("");
        }
      } else {
        toast.error("Email already added");
      }
    }
  };

  const removeEmail = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const handleEmailInputChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleReferEmailInputChange = (e) => {
    const input = e.target.value;
    setEmailInput(input);

    if (input.trim() === "") {
      setFilteredEmails([]);
    } else {
      const filtered = allEmails.filter(
        (email) =>
          email.toLowerCase().includes(input.toLowerCase()) &&
          !emails.includes(email)
      );
      setFilteredEmails(filtered);
    }
  };

  const handleEmailSelect = (email) => {
    if (!emails.includes(email)) {
      setEmails([...emails, email]);
      setEmailInput("");
      setFilteredEmails([]);
    } else {
      toast.error("Email already added");
    }
  };

  return (
    <>
      <div className="p-5 min-h-[180px] h-max max-lg:h-full  rounded-[5px] poppins shadow-md hover:bg-[#145EEE05] border border-[#F1F5FE]">
        <div className="flex flex-row items-start justify-between">
          <div className="flex-row">
            <p className="md:text-xl text-sm font-bold  leading-[24px] text-black mr-2">
              {job?.title}
            </p>
            <div className="flex flex-row items-center">
              <p className="md:text-sm text-xs font-medium	">{job?.address}</p>
            </div>
          </div>
          <div>
            <p className=" md:text-xs text-[10px] leading-[18px] text-[#565656] justify-end flex pr-16 max-lg:pr-4 ">
              Posted: {moment(job?.created_on).fromNow()}
            </p>
          </div>
        </div>
        <div className="my-4 flex flex-wrap gap-2">
          <span className={tagsStyle}>£{job?.budget ?? 0} x hr</span>
          {/* {job?.startDateTime?.map((dateTime, index) => ( */}
          {job?.startDateTime[0]?.date && (
            <span className={tagsStyle}>
              {moment(job?.startDateTime[0]?.date ?? "").format("DD/MM/YYYY")}
            </span>
          )}
          {/* ))} */}
          {job?.startDateTime?.length > 0 ? (
            <span className={tagsStyle}>{job?.startDateTime?.length} days</span>
          ) : null}
          <span className={tagsStyle}>AM - PM</span>
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-2">
          <div className="md:text-[14px] text-xs   px-2 text-left  leading-[21px] text-[#242424] mt-[20px] md:w-[70%]">
            <span className="line-clamp-2">{job?.description}</span>
            <span
              onClick={() => viewJob(job)}
              className="text-[#145EEE] cursor-pointer"
            >
              Read More
            </span>
          </div>
          <div className="flex flex-row pr-12 max-lg:pr-4 items-center ">
            {activeTab?.toString() === "jobs" && (
              <div className="flex">
                {/* <button
                  onClick={() => handleOpenChat(item)}
                  className={`bg-primary w-[36px] h-[36px] rounded-[10px] md:text-sm text-xs text-white justify-center flex items-center mr-3 `}
                >
                  <IoChatbubblesOutline size={25} color="white" />
                </button> */}
                {/* <div className="relative flex">
                  <button
                    onClick={() => handleShare(item)}
                    className={`bg-primary w-[36px] h-[36px] rounded-[10px] md:text-sm text-xs text-white justify-center flex items-center mr-3 `}
                  >
                    <RiShareForwardFill size={25} color="white" />
                  </button>

                  {isModalVisible && (
                    <div
                      className="absolute bg-white p-2 rounded shadow-md max-w-max-content"
                      style={{ bottom: "45px", left: "0" }}
                    >
                      <div className="flex space-x-2">
                        <FacebookShareButton url={shareUrl}>
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <LinkedinShareButton url={shareUrl}>
                          <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                      </div>
                    </div>
                  )}
                </div> */}
                <button
                  onClick={() => handleRefer(item)}
                  className="bg-primary w-[154px] h-[36px] rounded-[10px] text-sm text-white justify-center flex items-center mr-3"
                >
                  <span>Refer Co-workers</span>
                </button>
                <button
                  onClick={() => handleOpen(item)}
                  className="bg-primary w-[154px] h-[36px] rounded-[10px] text-sm text-white justify-center flex items-center mr-3"
                >
                  Invite Co-workers
                </button>
              </div>
            )}
            {activeTab?.toString() === "requested" ? (
              <button
                onClick={() => handler("requested", item)}
                className={`bg-[#18B234] w-[104px] h-[36px] rounded-[10px] text-sm text-white justify-center flex items-center mr-3`}
              >
                Confirm
              </button>
            ) : activeTab?.toString() === "applied" ? (
              <button
                onClick={() => handleOpenChat(item)}
                className={`bg-primary w-[36px] h-[36px] rounded-[10px] md:text-sm text-xs text-white justify-center flex items-center mr-3 `}
              >
                <IoChatbubblesOutline size={25} color="white" />
              </button>
            ) : activeTab?.toString() === "jobs" ? (
              <button
                onClick={() => handler("open", item)}
                className="bg-primary w-[104px] h-[36px] rounded-[10px] text-sm text-white justify-center flex items-center mr-3"
              >
                Apply job
              </button>
            ) : activeTab?.toString() === "hire_request" ? (
              <button
                onClick={() => handler("hire_request", item)}
                className="bg-[#18B234] w-[104px] h-[36px] rounded-[10px] text-sm text-white justify-center flex items-center mr-3"
              >
                Confirm
              </button>
            ) : activeTab?.toString() === "_" ? (
              <button
                onClick={() => handler("hired", item)}
                className="bg-[#18B234] w-[104px] h-[36px] rounded-[10px] text-sm text-white justify-center flex items-center mr-3"
              >
                I was Hired
              </button>
            ) : null}
            <button
              onClick={() => viewJob(job)}
              className="flex justify-center cursor-pointer items-center rounded-[10px] h-[36px] w-[36px] bg-[#E4E2E0] mr-3"
            >
              <GoInfo className="text-xl" />
            </button>

            {activeTab?.toString() !== "hired" &&
            activeTab?.toString() !== "declined" &&
            activeTab?.toString() !== "lost" &&
            activeTab?.toString() !== "hired" &&
            activeTab?.toString() !== "jobs" ? (
              <button
                onClick={() => declinedHandler(item)}
                className="flex cursor-pointer justify-center items-center rounded-[10px] h-[36px] w-[36px] bg-[#FF4C00] mr-3"
              >
                <RiForbid2Line className="text-xl text-white" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between items-center">
            <h3 className="md:text-xl text-xs font-bold text-black">
              Invite Co-workers for {selectedJob?.title}
            </h3>
            <button onClick={handleClose}>
              <IoMdClose size={20} color="black" />
            </button>
          </div>
          <div className="my-5">
            <input
              type="email"
              value={emailInput}
              onChange={handleEmailInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Enter email and press enter"
              className="fieldCss border p-2 rounded"
            />

            <div className="mt-4">
              {/* <h4 className="font-semibold">Added Emails:</h4> */}
              <div
                style={{ height: emails.length > 0 ? "100px" : "0" }}
                className="flex flex-col gap-2 my-2 overflow-auto"
              >
                {emails.map((email, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center p-2 rounded-[5px] w-max gap-5 bg-[#0070de] text-white"
                  >
                    <span>{email}</span>
                    <button onClick={() => removeEmail(email)}>
                      <IoMdClose size={20} color="white" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button
            disabled={emails.length === 0}
            className="bg-[#0070de] text-white p-2 rounded-[5px]"
            onClick={() => handleInvite(selectedJob)}
            style={{ backgroundColor: emails.length > 0 ? "#0070de" : "gray" }}
          >
            {inviteCoWorkersLoading ? (
              <img src={"/loading.svg"} alt="loading" className="w-6 h-6" />
            ) : (
              <p>Send Invite</p>
            )}
          </button>
        </Box>
      </Modal>

      <Modal
        open={referalOpen}
        onClose={handleReferalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between items-center">
            <h3 className="md:text-xl text-xs font-bold text-black">
              Refer Co-workers for {selectedJobToRefer?.title}
            </h3>
            <button onClick={handleReferalClose}>
              <IoMdClose size={20} color="black" />
            </button>
          </div>
          <div className="my-5">
            <input
              type="email"
              value={emailInput}
              onChange={handleReferEmailInputChange}
              placeholder="Enter email and press enter"
              className="fieldCss border p-2 rounded"
            />

            {/* Filtered email list */}
            {filteredEmails.length > 0 && (
              <ul className="border mt-2 p-2 rounded bg-white max-h-40 overflow-y-auto">
                {filteredEmails.map((email, index) => (
                  <li
                    key={index}
                    className="cursor-pointer text-black p-2 hover:bg-[#4477FE] hover:text-white rounded-[5px]"
                    onClick={() => handleEmailSelect(email)}
                  >
                    {email}
                  </li>
                ))}
              </ul>
            )}

            <div className="mt-4">
              <div
                style={{ height: emails.length > 0 ? "100px" : "0" }}
                className="flex flex-col gap-2 my-2 overflow-auto"
              >
                {emails.map((email, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center p-2 rounded-[5px] w-max gap-5 bg-[#0070de] text-white"
                  >
                    <span>{email}</span>
                    <button onClick={() => removeEmail(email)}>
                      <IoMdClose size={20} color="white" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button
            disabled={emails.length === 0}
            className="bg-[#0070de] text-white p-2 rounded-[5px]"
            onClick={() => handleReferGuard(selectedJobToRefer)}
            style={{ backgroundColor: emails.length > 0 ? "#0070de" : "gray" }}
          >
            {referCoWorkersLoading ? (
              <img src={"/loading.svg"} alt="loading" className="w-6 h-6" />
            ) : (
              <p>Refer</p>
            )}
          </button>
        </Box>
      </Modal>

      <Modal
        open={openChat}
        onClose={handleOpenChat}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={chatStyle}>
          <GeneralChat
            selectedJob={selectedJobForChat}
            onClose={handleCloseChat}
            role={"guard"}
            type={"general"}
            userId={userData?.id}
            roomUrl={`chat/conversation/start`}
            sendMessageUrl={`chat/message/send`}
            getMessageUrl={`chat/conversation`}
          />
        </Box>
      </Modal>
    </>
  );
};

export default JobList;
