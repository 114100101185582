import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { RiForbid2Line } from "react-icons/ri";
import { GoInfo } from "react-icons/go";
import image from "../../../../assets/images/photo.svg";
import verified from "../../../../assets/images/status/guard_verified.svg";
import recommended from "../../../../assets/images/status/guard_recommended.svg";
import { Drawer, Box, Typography, Button, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import person from "../../../../assets/images/person.png";
import moment from "moment";
import ReferedGuardDetails from "../components/ReferedGuardDetails";
import { useDispatch, useSelector } from "react-redux";
import { referedGuardDetailsAction } from "../../../../redux/client/action";
import { IoChatbubblesOutline } from "react-icons/io5";
import GeneralChat from "../../../ai-chat-bot/general-chat/GeneralChat";

const JobListCard = ({
  item,
  viewJob,
  declinedHandler,
  activeTab,
  handler,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [activeReferedByGuardId, setActiveReferedByGuardId] = useState(null);
  const { referedGuardDetails, referedGuardDetailsLoading } = useSelector(
    (state) => state.ClientSection
  );
  const [guardDetails, setGuardDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const user_data = localStorage.getItem("user_data");
  const userData = JSON.parse(user_data);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 4,
    borderRadius: 5,
    height: 400,
    // marginHorizontal: 10,
  };

  useEffect(() => {
    if (referedGuardDetails?.success && loading) {
      setOpen(true);
      setLoading(false);
      setActiveReferedByGuardId(null);
      setGuardDetails(referedGuardDetails?.data);
    }
  }, [referedGuardDetails]);

  useEffect(() => {
    if (activeReferedByGuardId) {
      dispatch(referedGuardDetailsAction(activeReferedByGuardId));
      setLoading(true);
    }
  }, [activeReferedByGuardId]);

  const handleOpen = (id) => {
    setActiveReferedByGuardId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setGuardDetails([]);
  };
  const tagsStyle =
    "bg-[#F6F5F5] text-[#5B5B5B] rounded-[10px] px-[30px] py-[5px] mr-4 md:text-base text-xs font-medium whitespace-nowrap";

  const isReviewed = item.reviews.findIndex(
    (i) => Number(i.job_id) === Number(id)
  );

  const handleChatModalOpen = (item) => {
    setChatModalOpen(true);
    setSelectedJob(item);
  };

  const handleChatModalClose = () => {
    setChatModalOpen(false);
  };

  return (
    <div className="p-5   rounded-[5px] poppins shadow-md hover:bg-[#145EEE05] border border-[#F1F5FE]">
      <div className="flex flex-row items-start justify-between">
        <div className="flex-row">
          <div className="flex  items-start ">
            <img //Use guard image if available
              width={700}
              height={700}
              src={
                item?.profile_image
                  ? item?.profile_image
                  : "/profile-image.webp"
              }
              alt=""
              className="w-16 h-16 rounded-full"
            />
            <div className="items-center pl-6">
              <div className="flex justify-between items-center w-full">
                <button onClick={() => viewJob(item)}>
                  <p className="md:text-[20px] text-sm font-bold  leading-[24px] text-black mr-2 ">
                    {item?.first_name} {item?.last_name}
                  </p>
                </button>
                {item?.licenses[0]?.status === "recommended" &&
                  item?.recommonded_by && (
                    <div className="flex gap-2 items-center">
                      <p className="font-medium">
                        (Recommended by{" "}
                        <button
                          className=" hover:text-blue-600"
                          onClick={() => handleOpen(item?.referredBy_id)}
                        >
                          {item?.recommonded_by}
                        </button>
                        )
                      </p>
                      {(referedGuardDetailsLoading || loading) && (
                        <img
                          src={"/loaing-colored.svg"}
                          alt="loading"
                          className="w-6 h-6"
                        />
                      )}
                    </div>
                  )}
              </div>
              <div className="md:text-[14px] text-xs font-medium">
                <Status status={item?.licenses[0]?.status} />
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* <p className=" text-[12px] leading-[18px] text-[#565656] justify-end flex pr-16 max-lg:pr-4 ">
             Posted: {timestamps} ago
            </p> */}
        </div>
      </div>
      <div className="my-4 flex flex-wrap gap-2">
        {item?.average_rating ? (
          <span className={tagsStyle}>
            <span className="text-[#366CFE]">
              {parseFloat(item?.average_rating ?? 5).toFixed(1)}
            </span>
            {"   "}({item?.reviews?.length ? item?.reviews?.length : 0} reviews)
          </span>
        ) : null}
        <span className={tagsStyle}>{item?.address}</span>
        {item.licenses?.slice(0, 3).map((license, index) => (
          <span className={tagsStyle} key={index}>
            {license?.sector}
          </span>
        ))}
      </div>
      <div className="flex md:flex-row flex-col justify-between gap-2">
        <div className="md:text-[14px] text-xs   px-2 text-left  leading-[21px] text-[#242424] mt-[20px] md:w-[70%]">
          <div className="line-clamp-2">{item?.guard_description}</div>
          <span
            onClick={() => viewJob(item)}
            className="text-[#145EEE] cursor-pointer "
          >
            Read More
          </span>
        </div>
        <div className="flex flex-row pr-12 max-lg:pr-4 items-center ">
          {activeTab === "people" && (
            <button
              type="button"
              onClick={() => handler(activeTab, item)}
              className="px-6 py-2.5 bg-[#145EEE] text-white rounded-xl mr-4 md:text-base text-xs"
            >
              Invite to job
            </button>
          )}
          {activeTab === "applied" && (
            <div className="flex gap-2 items-center">
              <button
                onClick={() => handleChatModalOpen(item)}
                className={`bg-primary w-[36px] h-[36px] rounded-[10px] md:text-sm text-xs text-white justify-center flex items-center mr-3 `}
              >
                <IoChatbubblesOutline size={25} color="white" />
              </button>
              <button
                type="button"
                onClick={() => handler(activeTab, item)}
                className="px-6 py-2.5 bg-[#3F8825] text-white rounded-xl mr-4 md:text-base text-xs"
              >
                Shortlist
              </button>
            </div>
          )}
          {activeTab === "shortlisted" && (
            <button
              type="button"
              onClick={() => handler(activeTab, item)}
              className="px-6 py-2.5 bg-[#3F8825] text-white rounded-xl mr-4 md:text-base text-xs w-max"
            >
              Send hire request
            </button>
          )}
          {activeTab === "hired" && isReviewed === -1 && (
            <button
              type="button"
              onClick={() => handler(activeTab, item)}
              className="px-6 py-2.5 bg-[#F0BA03] text-white rounded-xl mr-4 md:text-base text-xs"
            >
              Write review
            </button>
          )}
          {activeTab === "declined" && (
            <button
              type="button"
              onClick={() => handler(activeTab, item)}
              className="px-6 py-2.5 bg-[#3F8825] text-white rounded-xl mr-4 md:text-base text-xs"
            >
              Undo refusal
            </button>
          )}

          <button
            onClick={() => viewJob(item)}
            className="flex justify-center cursor-pointer items-center rounded-[10px] h-[36px] w-[36px] bg-[#E4E2E0] mr-3"
          >
            <GoInfo className="md:text-xl text-base" />
          </button>

          {activeTab?.toString() !== "declined" &&
          activeTab?.toString() !== "people" &&
          activeTab?.toString() !== "lost" &&
          activeTab?.toString() !== "hired" ? (
            <button
              onClick={() => declinedHandler(item)}
              className="flex cursor-pointer justify-center items-center rounded-[10px] h-[36px] w-[36px] bg-[#FF4C00] mr-3"
            >
              <RiForbid2Line className="md:text-xl text-base text-white" />
            </button>
          ) : null}
        </div>
      </div>
      <Drawer
        anchor="right"
        open={open}
        sx={{
          width: "70%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "70%",
            boxSizing: "border-box",
          },
        }}
        onClose={handleClose}
      >
        <div className="flex w-full h-screen">
          <div className="w-full overflow-y-auto">
            <div className="w-full min-h-screen bg-dull">
              <ReferedGuardDetails guardDetails={guardDetails} />
            </div>
          </div>
        </div>
      </Drawer>

      <Modal
        open={chatModalOpen}
        onClose={handleChatModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <GeneralChat
            selectedJob={selectedJob}
            onClose={handleChatModalClose}
            role={"client"}
            type={"general"}
            userId={userData?.id}
            roomUrl={`chat/conversation/start`}
            sendMessageUrl={`chat/message/send`}
            getMessageUrl={`chat/conversation`}
          />
        </Box>
      </Modal>
    </div>
  );
};

export const Status = ({ status }) => {
  let style, icon, text;

  switch (status?.toLowerCase()) {
    case "verified":
      style = "text-[#3F8825]";
      icon = verified;
      text = "Verified";
      break;

    case "recommended":
      style = "text-[#800080]";
      icon = recommended;
      text = "Recommended";
      break;

    default:
      break;
  }
  return (
    <div className={`flex items-center mt-2`}>
      <div>
        <img
          width={1000}
          height={1000}
          src={icon}
          alt="status"
          className="w-6 h-6 object-contain"
        />
      </div>
      <p className={`${style} text-[10px]`}>{text}</p>
    </div>
  );
};

export default JobListCard;
