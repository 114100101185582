import React, { useEffect, useState } from "react";
import "./ChatListing.css";
import person from "../../../../assets/images/person.png";
import { useSelector } from "react-redux";

const ChatListing = ({ setSelectedUser }) => {
  const { token } = useSelector((state) => state.Authsection);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/guard/chat/conversation/list/one-to-one`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    if (data) {
      setUsers(data?.data);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="w-[350px] p-4">
      <span className="text-[25px]">All Chats</span>
      <div className="h-[100%] overflow-auto">
        {users?.map((user) => {
          return (
            <button
              key={user?.conversation_id}
              className="flex gap-3 py-4 px-1 items-center border-b border-[#ededed] w-full rounded-[10px] hover:bg-[#ededed]"
              onClick={() => setSelectedUser(user)}
            >
              <div>
                <img
                  width={500}
                  height={500}
                  src={user?.profile_pic || person}
                  alt=""
                  className="w-14 h-14 rounded-full object-cover"
                />
              </div>
              <div className="flex flex-col items-start gap-0">
                <span className="font-[600] text-[18px]">Admin</span>
                {/* <span>
                  {user?.client_name}, {user?.guard_name}
                </span> */}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ChatListing;
