import { Box, Drawer, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SingleUserPage from "./components/SingleUserPage";
import SearchBar from "./components/SearchBar";
import UserTable from "./components/UserTable";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { getUsersBetweenDatesAction } from "../../../redux/subadmin/action";
import toast from "react-hot-toast";
import { CiSearch } from "react-icons/ci";

function Users() {
  const dispatch = useDispatch();
  const [currUser, setCurrUser] = useState(null);
  const { isClientUpdated } = useSelector((state) => state.SubadminSection);
  const { getUsers } = useSelector((state) => state.Adminsection);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  const { getUsersBetweenDates, getUsersBetweenDatesLoading } = useSelector(
    (state) => state.SubadminSection
  );
  const [exportAttempted, setExportAttempted] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.length >= 3 || searchTerm.length === 0) {
        setDebouncedSearchTerm(searchTerm);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]);
    const csvRows = [
      header.join(","),
      ...data.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ];
    return csvRows.join("\n");
  };

  const replacer = (key, value) => (value === null ? "" : value);

  const downloadCSV = (csv, filename = "users.csv") => {
    const csvBlob = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement("a");
    link.href = csvUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (getUsersBetweenDates && getUsersBetweenDates.data && exportAttempted) {
      if (getUsersBetweenDates.data.length > 0) {
        const csv = convertToCSV(getUsersBetweenDates.data);
        downloadCSV(csv, `clients.csv`);
        setOpen(false);
      } else if (exportAttempted) {
        toast.error("No clients were found between these dates");
      }
    }
  }, [getUsersBetweenDates, exportAttempted]);

  useEffect(() => {
    if (isClientUpdated === true && currUser === null) {
      window.location.reload(false);
    }
  }, [currUser, isClientUpdated]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    marginHorizontal: 10,
  };

  const exportCSVWithDate = () => {
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

      let payload = {
        start: formattedStartDate,
        end: formattedEndDate,
      };

      dispatch(getUsersBetweenDatesAction(payload));
      setStartDate(null);
      setEndDate(null);
      setExportAttempted(true);
    } else {
      toast.error("Please select both start and end dates.");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        sx={{
          width: "70%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "70%",
            boxSizing: "border-box",
          },
        }}
        open={currUser !== null}
        onClose={() => setCurrUser(null)}
        className="dashboard"
      >
        {currUser !== null ? (
          <SingleUserPage setCurrUser={setCurrUser} currUser={currUser} />
        ) : null}
      </Drawer>

      <div className="w-full min-h-screen bg-dull py-6 md:px-10 px-2">
        {/* <SearchBar /> */}
        <div className="flex flex-col items-start md:flex-row justify-between gap-5 items-center">
          <div className="border p-2 rounded-[15px] w-full bg-white flex items-center gap-2">
            <CiSearch size={25} color="gray" />
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search users by name or email (type atleast three characters)"
              className="outline-0 w-full"
            />
          </div>
          <button
            className="bg-[#0070de] hover:!bg-[#4585FF] text-white p-2 rounded-[5px] w-[230px]"
            onClick={() => setOpen(true)}
          >
            Export to CSV
          </button>
        </div>
        <UserTable setCurrUser={setCurrUser} searchTerm={debouncedSearchTerm} />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center text-[20px] font-medium">
            Select Dates
          </div>
          <div className="my-5 gap-5 flex flex-col">
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Enter Start Date"
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Enter End Date"
                    onChange={(newValue) => setEndDate(newValue)}
                    minDate={startDate}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          <div className="mt-10 flex justify-center">
            <button
              className="bg-[#0070de] text-white p-2 rounded-[5px] w-[100px] flex justify-center"
              onClick={exportCSVWithDate}
            >
              {getUsersBetweenDatesLoading ? (
                <img src={"/loading.svg"} alt="loading" className="w-6 h-6" />
              ) : (
                <span>Export</span>
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Users;
