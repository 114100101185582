import { Drawer } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReviewsAction } from "../../../redux/subadmin/action";
import SearchBar from "../users/components/SearchBar";
import SingleReviewPage from "./components/SingleReviewPage";
import ReviewsTable from "./components/ReviewsTable";
const Reviews = () => {
  const [currReview, setCurrReview] = useState(null);
  const dispatch = useDispatch();
  const { getReviews } = useSelector((state) => state.SubadminSection);
  React.useEffect(() => {
    dispatch(getReviewsAction());
  }, [dispatch]);
  return (
    <>
      <Drawer
      sx={{
        width: "70%",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "70%",
          boxSizing: "border-box",
        },
       
      }}
        anchor={"right"}
        open={currReview !== null}
        onClose={() => setCurrReview(null)}
        className="dashboard"
      >
        {currReview !== null ? (
          <SingleReviewPage setCurrReview={setCurrReview} currReview={currReview} />
        ) : null}
      </Drawer>
      
          <div className="w-full  bg-dull md:py-6 py-2 md:px-10 px-2">
            {/* <SearchBar /> */}
            <ReviewsTable setCurrReview={setCurrReview} reviews={getReviews} />
          </div>
        
    </>
  );
};

export default Reviews;
