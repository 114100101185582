import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginDetailsAction } from "../../../../redux/subadmin/action";
import person from "../../../../assets/images/person.png";

import moment from "moment";
import ListLoader from "../../../loader/listLoader";
import Pagination from "../../../common/Pagination";

const PAGE_SIZE = 10;

const LoginDetailsTable = ({ setCurrUser }) => {
  const dispatch = useDispatch();
  const { loginDetails, loginDetailsLoading } = useSelector(
    (state) => state.SubadminSection
  );
  const [totalResult, setTotalResult] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    dispatch(
      loginDetailsAction({
        limit: limit,
        page: skip,
      })
    );
  }, [skip]);

  useEffect(() => {
    if (totalResult > 0) {
      setTotalPages(Math.ceil(totalResult / PAGE_SIZE));
    }
  }, [totalResult]);

  useEffect(() => {
    if (loginDetails?.data?.count?.total > 0) {
      setTotalResult(loginDetails?.data?.count?.total);
    }
  }, [loginDetails]);

  useEffect(() => {
    dispatch(loginDetailsAction({ limit: limit, page: skip }));
  }, [dispatch]);

  const handlePageChange = useCallback((page) => {
    setSkip(page);
  }, []);

  const getPageNumbers = useCallback(() => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }, [totalPages]);

  const getDateTime = (dateTime) => {
    console.log(dateTime);
    const utcDate = new Date(dateTime);

    const localDate = utcDate.toLocaleDateString();
    const localTime = utcDate.toLocaleTimeString();

    const localDateTime = `${localDate} ${localTime}`;

    return localDateTime;
  };

  return (
    <>
      <TableContainer
        // sx={{fontFamily: poppins.style.fontFamily }}
        className={"mt-5 rounded-xl"}
        component={Paper}
        // sx={{ overflowX: "auto" }}
      >
        <Table
          // sx={{fontFamily: poppins.style.fontFamily }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                {/* <input type='checkbox' /> */}
              </TableCell>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                User name
              </TableCell>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                Email address
              </TableCell>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                Phone number
              </TableCell>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                Logged in{" "}
              </TableCell>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                Logged out{" "}
              </TableCell>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                Active status{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loginDetailsLoading ? (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  colSpan={7}
                  align="center"
                  // sx={{ fontFamily: poppins.style.fontFamily }}
                >
                  <ListLoader />
                </TableCell>
              </TableRow>
            ) : loginDetails?.data?.activity?.length > 0 ? (
              loginDetails?.data?.activity.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    sx={
                      {
                        // fontFamily: poppins.style.fontFamily
                      }
                    }
                  >
                    {/* <input type='checkbox' /> */}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={
                      {
                        // fontFamily: poppins.style.fontFamily
                      }
                    }
                    className="flex flex-col gap-2 items-center justify-center"
                    style={{ display: "flex" }}
                    component="th"
                    scope="row"
                  >
                    <img
                      width={500}
                      height={500}
                      alt=""
                      src={person}
                      className="w-8 h-8 rounded-full"
                    />
                    <div className="flex flex-col">
                      <button onClick={() => setCurrUser(row)}>
                        <p className="md:text-sm text-[10px] font-semibold hover:text-blue-500">
                          {row.name}
                        </p>
                      </button>
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={
                      {
                        // fontFamily: poppins.style.fontFamily
                      }
                    }
                    className="md:text-base text-xs"
                  >
                    {row.email}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={
                      {
                        // fontFamily: poppins.style.fontFamily
                      }
                    }
                    className="md:text-base text-xs"
                  >
                    {row.phone}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={
                      {
                        // fontFamily: poppins.style.fontFamily
                      }
                    }
                    className="md:text-base text-xs"
                  >
                    {moment(row.last_loggedin).format(
                      "ddd, MMM Do YYYY, hh:mm:ss a"
                    )}
                    {/* {getDateTime(row.last_loggedin)} */}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={
                      {
                        // fontFamily: poppins.style.fontFamily
                      }
                    }
                    className="md:text-base text-xs"
                  >
                    {row.last_loggedout
                      ? moment(row.last_loggedout).format(
                          "ddd, MMM Do YYYY, hh:mm:ss a"
                        )
                      : "-"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={
                      {
                        // fontFamily: poppins.style.fontFamily
                      }
                    }
                    className="md:text-base text-xs"
                  >
                    <p
                      className="text-[60px]"
                      style={{ color: row.isActive ? "green" : "red" }}
                    >
                      •
                    </p>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell colSpan={7} align="center">
                  No data yet.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 0 && (
        <div className="pagination_box">
          <Pagination
            currentPage={skip}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            getPageNumbers={getPageNumbers}
          />
        </div>
      )}
    </>
  );
};

export default LoginDetailsTable;
