import {
  GET_DASHBOARD,
  GET_DASHBOARD_ERROR,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_CHARTS,
  GET_DASHBOARD_CHARTS_ERROR,
  GET_DASHBOARD_CHARTS_SUCCESS,
  GET_TRADE_MEMBERS,
  GET_TRADE_MEMBERS_ERROR,
  GET_TRADE_MEMBERS_SUCCESS,
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  GET_ADMIN_JOBS,
  GET_ADMIN_JOBS_ERROR,
  GET_ADMIN_JOBS_SUCCESS,
  GET_REVIEWS,
  GET_REVIEWS_ERROR,
  GET_REVIEWS_SUCCESS,
  PATCH_TRADE_MEMBERS,
  PATCH_TRADE_MEMBERS_ERROR,
  PATCH_TRADE_MEMBERS_SUCCESS,
  DELETE_USERS,
  DELETE_USERS_ERROR,
  DELETE_USERS_SUCCESS,
  PATCH_JOBS,
  PATCH_JOBS_ERROR,
  PATCH_JOBS_SUCCESS,
  PATCH_LICENSE,
  PATCH_LICENSE_ERROR,
  PATCH_LICENSE_SUCCESS,
  TRADER_UPDATED,
  PATCH_CLIENT,
  PATCH_CLIENT_ERROR,
  PATCH_CLIENT_SUCCESS,
  CLIENT_UPDATED,
  JOB_UPDATED,
  PATCH_REVIEWS,
  PATCH_REVIEWS_ERROR,
  PATCH_REVIEWS_SUCCESS,
  GET_TRADE_MEMBERS_BETWEEN_DATES,
  GET_TRADE_MEMBERS_BETWEEN_DATES_SUCCESS,
  GET_TRADE_MEMBERS_BETWEEN_DATES_ERROR,
  GET_USERS_BETWEEN_DATES,
  GET_USERS_BETWEEN_DATES_SUCCESS,
  GET_USERS_BETWEEN_DATES_ERROR,
  GET_JOBS_BETWEEN_DATES,
  GET_JOBS_BETWEEN_DATES_SUCCESS,
  GET_JOBS_BETWEEN_DATES_ERROR,
  GET_FILTERED_TRADE_MEMBERS,
  GET_FILTERED_TRADE_MEMBERS_SUCCESS,
  GET_FILTERED_TRADE_MEMBERS_ERROR,
  UPDATE_ADMIN_PASSWORD,
  UPDATE_ADMIN_PASSWORD_SUCCESS,
  UPDATE_ADMIN_PASSWORD_ERROR,
  UPDATE_PASSOWRD,
  UPDATE_PASSOWRD_SUCCESS,
  UPDATE_PASSOWRD_ERROR,
  LOGIN_DETAILS_DATA,
  LOGIN_DETAILS_DATA_SUCCESS,
  LOGIN_DETAILS_DATA_ERROR,
  UPLOAD_JOB_BANNER,
  UPLOAD_JOB_BANNER_SUCCESS,
  UPLOAD_JOB_BANNER_ERROR,
  GET_UPLOAD_JOB_BANNER,
  GET_UPLOAD_JOB_BANNER_SUCCESS,
  GET_UPLOAD_JOB_BANNER_ERROR,
  UPDATE_JOB_BANNER,
  UPDATE_JOB_BANNER_SUCCESS,
  UPDATE_JOB_BANNER_ERROR,
  ADD_SUB_ADMIN,
  ADD_SUB_ADMIN_SUCCESS,
  ADD_SUB_ADMIN_ERROR,
  UPDATE_SUB_ADMIN,
  UPDATE_SUB_ADMIN_SUCCESS,
  UPDATE_SUB_ADMIN_ERROR,
  GET_SUB_ADMIN,
  GET_SUB_ADMIN_SUCCESS,
  GET_SUB_ADMIN_ERROR,
} from "../actions";

export const patchReviewAction = (info) => ({
  type: PATCH_REVIEWS,
  payload: info,
});

export const patchReviewSuccess = (info) => ({
  type: PATCH_REVIEWS_SUCCESS,
  payload: info,
});

export const patchReviewError = (info) => ({
  type: PATCH_REVIEWS_ERROR,
  payload: info,
});

export const jobUpdated = () => ({
  type: JOB_UPDATED,
});

export const traderUpdated = () => ({
  type: TRADER_UPDATED,
});

export const clientUpdated = () => ({
  type: CLIENT_UPDATED,
});

export const patchClientAction = (info) => ({
  type: PATCH_CLIENT,
  payload: info,
});

export const patchClientSuccess = (info) => ({
  type: PATCH_CLIENT_SUCCESS,
  payload: info,
});

export const patchClientError = (info) => ({
  type: PATCH_CLIENT_ERROR,
  payload: info,
});

export const getDashboardAction = (info) => ({
  type: GET_DASHBOARD,
  payload: info,
});
export const getDashboardSuccess = (info) => ({
  type: GET_DASHBOARD_SUCCESS,
  payload: info,
});
export const getDashboardError = (error) => ({
  type: GET_DASHBOARD_ERROR,
  payload: error,
});
export const getDashboardChartsAction = (info) => ({
  type: GET_DASHBOARD_CHARTS,
  payload: info,
});
export const getDashboardChartsSuccess = (info) => ({
  type: GET_DASHBOARD_CHARTS_SUCCESS,
  payload: info,
});
export const getDashboardChartsError = (error) => ({
  type: GET_DASHBOARD_CHARTS_ERROR,
  payload: error,
});
export const getTradeMembersAction = (info) => ({
  type: GET_TRADE_MEMBERS,
  payload: info,
});
export const getTradeMembersSuccess = (info) => ({
  type: GET_TRADE_MEMBERS_SUCCESS,
  payload: info,
});
export const getTradeMembersError = (error) => ({
  type: GET_TRADE_MEMBERS_ERROR,
  payload: error,
});
export const getUsersAction = (info) => ({
  type: GET_USERS,
  payload: info,
});
export const getUsersSuccess = (info) => ({
  type: GET_USERS_SUCCESS,
  payload: info,
});
export const getUsersError = (error) => ({
  type: GET_USERS_ERROR,
  payload: error,
});
export const getJobsAction = (info) => ({
  type: GET_ADMIN_JOBS,
  payload: info,
});
export const getJobsSuccess = (info) => ({
  type: GET_ADMIN_JOBS_SUCCESS,
  payload: info,
});
export const getJobsError = (error) => ({
  type: GET_ADMIN_JOBS_ERROR,
  payload: error,
});
export const getReviewsAction = (info) => ({
  type: GET_REVIEWS,
  payload: info,
});
export const getReviewsSuccess = (info) => ({
  type: GET_REVIEWS_SUCCESS,
  payload: info,
});
export const getReviewsError = (error) => ({
  type: GET_REVIEWS_ERROR,
  payload: error,
});
export const patchTradeMembersAction = (info) => ({
  type: PATCH_TRADE_MEMBERS,
  payload: info,
});
export const patchTradeMembersSuccess = (info) => ({
  type: PATCH_TRADE_MEMBERS_SUCCESS,
  payload: info,
});
export const patchTradeMembersError = (error) => ({
  type: PATCH_TRADE_MEMBERS_ERROR,
  payload: error,
});
export const patchJobAction = (info) => ({
  type: PATCH_JOBS,
  payload: info,
});
export const patchJobSuccess = (info) => ({
  type: PATCH_JOBS_SUCCESS,
  payload: info,
});
export const patchJobError = (error) => ({
  type: PATCH_JOBS_ERROR,
  payload: error,
});

export const deleteUserAction = (info) => ({
  type: DELETE_USERS,
  payload: info,
});
export const deleteUserSuccess = (info) => ({
  type: DELETE_USERS_SUCCESS,
  payload: info,
});
export const deleteUserError = (error) => ({
  type: DELETE_USERS_ERROR,
  payload: error,
});

export const patchLicenseAction = (info) => ({
  type: PATCH_LICENSE,
  payload: info,
});
export const patchLicenseSuccess = (info) => ({
  type: PATCH_LICENSE_SUCCESS,
  payload: info,
});
export const patchLicenseError = (error) => ({
  type: PATCH_LICENSE_ERROR,
  payload: error,
});
export const getTradeMembersBetweenDatesAction = (info) => ({
  type: GET_TRADE_MEMBERS_BETWEEN_DATES,
  payload: info,
});
export const getTradeMembersBetweenDatesSuccess = (info) => ({
  type: GET_TRADE_MEMBERS_BETWEEN_DATES_SUCCESS,
  payload: info,
});
export const getTradeMembersBetweenDatesError = (error) => ({
  type: GET_TRADE_MEMBERS_BETWEEN_DATES_ERROR,
  payload: error,
});
export const getUsersBetweenDatesAction = (info) => ({
  type: GET_USERS_BETWEEN_DATES,
  payload: info,
});
export const getUsersBetweenDatesSuccess = (info) => ({
  type: GET_USERS_BETWEEN_DATES_SUCCESS,
  payload: info,
});
export const getUsersBetweenDatesError = (error) => ({
  type: GET_USERS_BETWEEN_DATES_ERROR,
  payload: error,
});
export const getJobsBetweenDatesAction = (info) => ({
  type: GET_JOBS_BETWEEN_DATES,
  payload: info,
});
export const getJobsBetweenDatesSuccess = (info) => ({
  type: GET_JOBS_BETWEEN_DATES_SUCCESS,
  payload: info,
});
export const getJobsBetweenDatesError = (error) => ({
  type: GET_JOBS_BETWEEN_DATES_ERROR,
  payload: error,
});
export const getFilteredTradeMembersAction = (info) => ({
  type: GET_FILTERED_TRADE_MEMBERS,
  payload: info,
});
export const getFilteredTradeMembersSuccess = (info) => ({
  type: GET_FILTERED_TRADE_MEMBERS_SUCCESS,
  payload: info,
});
export const getFilteredTradeMembersError = (error) => ({
  type: GET_FILTERED_TRADE_MEMBERS_ERROR,
  payload: error,
});
export const updateAdminPasswordAction = (info) => ({
  type: UPDATE_ADMIN_PASSWORD,
  payload: info,
});
export const updateAdminPasswordSuccess = (info) => ({
  type: UPDATE_ADMIN_PASSWORD_SUCCESS,
  payload: info,
});
export const updateAdminPasswordError = (error) => ({
  type: UPDATE_ADMIN_PASSWORD_ERROR,
  payload: error,
});

export const updatePasswordAction = (info) => ({
  type: UPDATE_PASSOWRD,
  payload: info,
});
export const updatePasswordSuccess = (info) => ({
  type: UPDATE_PASSOWRD_SUCCESS,
  payload: info,
});
export const updatePasswordError = (error) => ({
  type: UPDATE_PASSOWRD_ERROR,
  payload: error,
});

export const loginDetailsAction = (info) => ({
  type: LOGIN_DETAILS_DATA,
  payload: info,
});
export const loginDetailsSuccess = (info) => ({
  type: LOGIN_DETAILS_DATA_SUCCESS,
  payload: info,
});
export const loginDetailserror = (error) => ({
  type: LOGIN_DETAILS_DATA_ERROR,
  payload: error,
});

export const uploadJobBannerAction = (info) => ({
  type: UPLOAD_JOB_BANNER,
  payload: info,
});
export const uploadJobBannerSuccess = (info) => ({
  type: UPLOAD_JOB_BANNER_SUCCESS,
  payload: info,
});
export const uploadJobBannerError = (error) => ({
  type: UPLOAD_JOB_BANNER_ERROR,
  payload: error,
});

export const getUploadedJobBannersAction = (info) => ({
  type: GET_UPLOAD_JOB_BANNER,
  payload: info,
});
export const getUploadedJobBannersSuccess = (info) => ({
  type: GET_UPLOAD_JOB_BANNER_SUCCESS,
  payload: info,
});
export const getUploadedJobBannersError = (error) => ({
  type: GET_UPLOAD_JOB_BANNER_ERROR,
  payload: error,
});

export const updateJobBannerAction = (info) => ({
  type: UPDATE_JOB_BANNER,
  payload: info,
});
export const updateJobBannerSuccess = (info) => ({
  type: UPDATE_JOB_BANNER_SUCCESS,
  payload: info,
});
export const updateJobBannerError = (error) => ({
  type: UPDATE_JOB_BANNER_ERROR,
  payload: error,
});

export const addSubAdminAction = (info) => ({
  type: ADD_SUB_ADMIN,
  payload: info,
});
export const addSubAdminSuccess = (info) => ({
  type: ADD_SUB_ADMIN_SUCCESS,
  payload: info,
});
export const addSubAdminError = (error) => ({
  type: ADD_SUB_ADMIN_ERROR,
  payload: error,
});

export const updateSubAdminAction = (info) => ({
  type: UPDATE_SUB_ADMIN,
  payload: info,
});
export const updateSubAdminSuccess = (info) => ({
  type: UPDATE_SUB_ADMIN_SUCCESS,
  payload: info,
});
export const updateSubAdminError = (error) => ({
  type: UPDATE_SUB_ADMIN_ERROR,
  payload: error,
});

export const getSubAdminsAction = (info) => ({
  type: GET_SUB_ADMIN,
  payload: info,
});
export const getSubAdminsSuccess = (info) => ({
  type: GET_SUB_ADMIN_SUCCESS,
  payload: info,
});
export const getSubAdminsError = (error) => ({
  type: GET_SUB_ADMIN_ERROR,
  payload: error,
});

