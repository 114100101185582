import React, { useEffect, useRef, useState } from "react";
import "./ChatScreen.css";
import { IoMdClose } from "react-icons/io";
import { IoMdSend } from "react-icons/io";
import { PiDotsThreeOutlineLight } from "react-icons/pi";

import axios from "axios";
import { formatText } from "../../../utils/Util";
import { GoogleGenerativeAI } from "@google/generative-ai";

const API_KEY = "AIzaSyBRFMdiF_YAlrnMpWGj3xbhvALVVZQoe58";
const genAI = new GoogleGenerativeAI(API_KEY);

const model = genAI.getGenerativeModel({
  model: "gemini-1.5-flash",
  systemInstruction: "You are an ai chatbot that give guard job relatd data",
});

const generationConfig = {
  temperature: 1,
  topP: 0.95,
  topK: 64,
  maxOutputTokens: 8192,
  responseMimeType: "text/plain",
};

const ChatScreen = ({ isOpen, onClose }) => {
  const inputRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [generatingAnswer, setGeneratingAnswer] = useState(false);

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const handleSend = async () => {
    // if (input.trim()) {
    //   setMessages([...messages, { text: input, sender: "user" }]);
    //   setInput("");
    //   setTimeout(() => {
    //     setMessages((prevMessages) => [
    //       ...prevMessages,
    //       { text: "Received: " + input, sender: "bot" },
    //     ]);
    //   }, 1000);
    // }
    const chatSession = model.startChat({
      generationConfig,
    });

    setGeneratingAnswer(true);
    setInput("");
    try {
      // const response = await axios({
      //   url: `https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=${API_KEY}`,
      //   method: "post",
      //   data: {
      //     contents: [{ parts: [{ text: input }] }],
      //   },
      // });

      const response = await chatSession.sendMessage(input);
      // console.log(response);
      // return;

      setMessages([...messages, { text: input, sender: "user" }]);
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: `${response["response"]["candidates"][0]["content"]["parts"][0]["text"]}`,
            sender: "bot",
          },
        ]);
      }, 100);
    } catch (error) {
      console.log(error);
      // setAnswer("Sorry - Something went wrong. Please try again!");
    }

    setGeneratingAnswer(false);
  };

  return (
    <div className={`chat-screen ${isOpen ? "open" : ""}`}>
      <div className="chat-header">
        <span>IGardu AI ChatBot</span>
        <button onClick={onClose}>
          <IoMdClose size={25} color="white" />
        </button>
      </div>
      <div className="chat-body">
        {messages.map((msg, index) => (
          <div key={index} className={`chat-message ${msg.sender}`}>
            {msg.sender === "bot" ? formatText(msg.text) : msg.text}
          </div>
        ))}
      </div>
      {generatingAnswer && (
        <div className="flex gap-2 justify-center items-center bg-[#007bff] p-2">
          <img src="/loading.svg" alt="loading" className="w-8 h-8" />
          <div className="wait-info">Wait till we fetch data!</div>
        </div>
      )}
      <div className="chat-input">
        <input
          ref={inputRef}
          disabled={generatingAnswer ? true : false}
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
          placeholder="Type a message..."
        />
        {generatingAnswer ? (
          <button disabled className="inactive-send">
            <PiDotsThreeOutlineLight size={25} color="white" />
          </button>
        ) : (
          <button onClick={handleSend} className="active-send">
            <IoMdSend size={25} color="white" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ChatScreen;
