import React, { useEffect, useState } from "react";
// import personImages from "../../../assets/images/home/person.png";
import personImages from "../../../../assets/images/home/homepage_banner.webp";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRole, getToken } from "../../../../redux/auth/action";
import arrow_right from "../../../../assets/images/about-us/arrow_right.svg";
import { motion } from "framer-motion";

const _initialLeft = { x: "-40%", opacity: 0 };
const _whileInViewLeft = {
  x: 0,
  opacity: 1,
  transition: {
    delay: 0.2,
    duration: 0.8,
  },
};

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

const _viewport = { once: true };

const HeroSection = () => {
  const [login, setLogin] = useState(false);
  const [loginRole, setLoginRole] = useState("");
  const { token } = useSelector((state) => state.Authsection);
  const { role } = useSelector((state) => state.Authsection);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getToken());
    dispatch(getRole());
  }, []);

  useEffect(() => {
    if (token !== null && role !== null) {
      setLogin(true);
      setLoginRole(role);
    } else {
      setLogin(false);
      setLoginRole("");
    }
  }, [token, role]);

  return (
    <>
      <div
        className={`w-full  px-[20px] pt-[20px] mt-[100px] mb-[50px] lg:mb-[100px]`}
      >
        <div className="max-w-[1340px] mx-auto flex gap-[50px] lg:gap-0 flex-col lg:flex-row  items-center justify-center ">
          <div className="flex-1">
            <motion.h1
              initial={_initialLeft}
              whileInView={{
                ..._whileInViewLeft,
                transition: {
                  duration: 0.8,
                  delay: 0.3,
                },
              }}
              viewport={_viewport}
              className="text-[#000000] text-center lg:text-left font-[600] max-w-[600px] leading-[clamp(52px,calc(60/1440*100vw),60px)] text-[clamp(48px,calc(52/1440*100vw),52px)] lg:pr-[32px]"
            >
              <motion.span>
                The easiest way to discover & hire skilled
              </motion.span>
              <motion.span className="text-[#145DEB] italic font-['Lora']">
                {" "}
                security personnel
              </motion.span>
            </motion.h1>

            <motion.p
              initial={_initialLeft}
              whileInView={{
                ..._whileInViewLeft,
                transition: {
                  duration: 0.8,
                  delay: 0.5,
                },
              }}
              viewport={_viewport}
              className="text-[#64748B] text-center lg:text-left font-[400] max-w-[517px] leading-[24px] text-[16px] mt-[24px]"
            >
              Streamlined Security Staffing: Discover, connect, and hire
              Top-tier Security Experts with Ease.
            </motion.p>

            <motion.div
              initial={_initialLeft}
              whileInView={{
                ..._whileInViewLeft,
                transition: {
                  duration: 0.8,
                  delay: 0.8,
                },
              }}
              viewport={_viewport}
              className="flex items-center justify-center lg:justify-start gap-[16px] mt-[32px]"
            >
              <Link to="/post-a-job">
                <button className=" transition-all duration-300 text-white  bg-[#145DEB]   py-[12px] px-[28px] border-[#4c3cff] border  rounded-[36px] text-[14px] md:text-[16px]">
                  Get Started
                </button>
              </Link>

              {login ? (
                <Link
                  to={
                    loginRole !== "admin"
                      ? `/${loginRole}/dashboard`
                      : "/dashboard"
                  }
                >
                  <button className="flex items-center gap-[6px] transition-all duration-300   text-[#323539]  py-[12px] px-[28px] border-[#E5E5E7] border rounded-[36px] text-[14px] md:text-[16px]">
                    Dashboard <img src={arrow_right} alt="arrow_right" />
                  </button>
                </Link>
              ) : (
                <Link to="/sign-in">
                  <button className="flex items-center gap-[6px] transition-all duration-300   text-[#323539]  py-[12px] px-[28px] border-[#E5E5E7] border rounded-[36px] text-[14px] md:text-[16px]">
                    Login <img src={arrow_right} alt="arrow_right" />
                  </button>
                </Link>
              )}
            </motion.div>
          </div>
          <div className="flex-1">
            <motion.img
              initial={_inital}
              whileInView={_whileInView}
              viewport={_viewport}
              src={personImages}
              height={616 * 2}
              width={489 * 2}
              className=" sm:mb-[-50px]"
            />
          </div>
        </div>
      </div>

      {/* <div className="md:p-36 p-4 mt-10 bg-white grid lg:grid-cols-2 grid-cols-1 fort-['Lora']">
        <div className="m-auto md:w-2/3 w-full order-2 lg:order-1">
          <div
            className="text-[#1A202C] font-semibold md:text-5xl text-2xl  "
            style={{ lineHeight: "52px" }}>
            The easiest way to discover & hire skilled{" "}
          </div>
          <div className="text-[#145DEB] md:text-5xl text-2xl font-semibold font-['Neue Haas Grotesk Display Pro'] ">
            security personnel
          </div>
          <p className="text-[#64748B] mt-6">
            Streamlined Security Staffing: Discover, Connect, and Hire Top-tier
            Security Experts with Ease.
          </p>
          <div className="flex flex-col md:flex-row gap-4 mt-8 ">
            <button
              type="button"
              className="text-white font-semibold bg-[#145DEB] hover:bg-[#22355c] py-3 px-7 rounded-3xl">
              Get Started
            </button>
            {login ? (
              <Link
                to={
                  loginRole !== "admin"
                    ? `/${loginRole}-dashboard`
                    : "/dashboard"
                }>
                <button
                  type="button"
                  className="flex gap-2 py-3 px-7 rounded-3xl bg-white hover:bg-black text-black hover:text-white border border-[#E5E5E7] justify-center">
                  Dashboard <FaArrowRightLong className="my-auto" />
                </button>
              </Link>
            ) : (
              <Link to="/sign-in">
                <button
                  type="button"
                  className="flex gap-2 py-3 px-7 rounded-3xl bg-white hover:bg-black text-black hover:text-white border border-[#E5E5E7] justify-center w-full md:w-fit">
                  Login <FaArrowRightLong className="my-auto" />
                </button>
              </Link>
            )}
          </div>
        </div>
        <div className="m-auto order-1 lg:order-2">
          <img src={personImages} height={616} width={489} />
        </div>
      </div> */}
    </>
  );
};

export default HeroSection;

// import React from "react";
// import LeftArrow from "../../assets/home/Icons/right.png";

// const HeroSection = () => {
//   return (
//     <div className="mx-auto w-screen px-28 mt-10 pt-12 pb-20 ">
//       <div className="mx-auto max-w-[1440px] w-screen flex justify-center items-center">
//         <div className="flex-col justify-center items-start gap-8 inline-flex">
//           <div className="flex-col justify-start items-start gap-3 flex">
//             <div className="self-stretch flex-col justify-start items-start gap-6 flex">
//               <div className="w-[517px]">
//                 <span className="text-gray-900 text-[52px] font-semibold Neue_Haas_Grotesk_Display_Pro leading-[60px]">
//                   The easiest way to discover & hire skilled{" "}
//                 </span>
//                 <span className="text-blue-600 text-[52px] font-semibold font-['Lora'] leading-[60px]">
//                   security personnel{" "}
//                 </span>
//               </div>
//               <div className="w-[404px] text-slate-500 text-base font-normal font-['Instrument Sans'] leading-normal">
//                 Streamlined Security Staffing: Discover, Connect, and Hire
//                 Top-tier Security Experts with Ease.
//               </div>
//             </div>
//           </div>
//           <div className="justify-start items-center gap-4 inline-flex">
//             <button
//               type="button"
//               className=" px-5 py-3 bg-blue-600 rounded-[36px] shadow justify-center items-center gap-1.5 flex hover:border-primary hover:bg-primary hover:text-white text-white text-[15px] font-semibold font-['Plus Jakarta Sans'] leading-snug z-40"
//             >
//               Get Started
//             </button>

//             <button
//               type="button"
//               className="px-5 py-3 bg-white hover:border-primary hover:bg-primary  rounded-[35px] shadow border border-zinc-200 justify-center items-center gap-1.5 flex text-neutral-700 text-[15px] hover:text-white font-semibold font-['Plus Jakarta Sans'] leading-snug"
//             >
//               Login
//               <FaArrowRightLong className="text-xl  text-neutral-700  " />
//             </button>
//           </div>
//         </div>
//         <img className="w-[616px] h-[489px]" src={personImages.src} />
//       </div>
//     </div>
//   );
// };

// export default HeroSection;
