import React, { useCallback, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ListLoader from "../../../loader/listLoader";
import { Box, Typography, Modal, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from "@mui/material";
import toast from "react-hot-toast";
import person from "../../../../assets/images/person.png";
import active from "../../../../assets/images/dashboard/active.svg";
import filled from "../../../../assets/images/dashboard/filled.svg";
import open from "../../../../assets/images/dashboard/open.svg";
import suspended from "../../../../assets/images/dashboard/suspended.svg";
import notactive from "../../../../assets/images/dashboard/notactive.svg";
import Pagination from "../../../common/Pagination";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDelete } from "react-icons/md";
import { getSubAdminsAction } from "../../../../redux/admin/action";
import { FaEdit } from "react-icons/fa";

const PAGE_SIZE = 10;

export default function SubAdminsTable({
  subAdmins,
  subAdminsLoading,
  setOpen,
  setSelectedSubAdmin,
  handleEdit,
}) {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.Authsection);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState({});
  const [totalResult, setTotalResult] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedSubAdminId, setSelectedSubadminId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  // useEffect(() => {
  //   dispatch(
  //     getUsersAction({
  //       limit: limit,
  //       skip: skip,
  //       keywords: searchTerm ? searchTerm : "",
  //     })
  //   );
  // }, [skip, searchTerm]);

  useEffect(() => {
    if (totalResult > 0) {
      setTotalPages(Math.ceil(totalResult / PAGE_SIZE));
    }
  }, [totalResult]);

  useEffect(() => {
    if (subAdmins?.meta?.total > 0) {
      setTotalResult(subAdmins?.meta?.total);
    }
  }, [subAdmins]);

  // useEffect(() => {
  //   dispatch(
  //     getUsersAction({ limit: limit, skip: skip, keywords: searchTerm })
  //   );
  // }, [searchTerm]);

  // useEffect(() => {
  //   dispatch(getUsersAction({ limit: limit, skip: skip, keywords: "" }));
  // }, [dispatch]);

  // useEffect(() => {
  //   if (patchClient.success) {
  //     toast.success(patchClient.message);
  //     setOpenModal(false);
  //     window.location.reload(false);
  //   } else if (patchClient.success === false) {
  //     toast.error(patchClient.message);
  //     setOpenModal(false);
  //   }
  // }, [dispatch, patchClient]);

  const handlePageChange = useCallback((page) => {
    setSkip(page);
  }, []);

  const getPageNumbers = useCallback(() => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }, [totalPages]);

  const handleDelete = (id) => {
    setSelectedSubadminId(id);
    setModalOpen(true);
  };

  const handleClose = () => {
  setModalOpen(false);
    setSelectedSubadminId(null);
  };

  const deleteSubadmin = async (id) => {
    console.log(id);

    const result = await fetch(
      `${process.env.REACT_APP_BASE_URL}/auth/user/${id}/delete`,
      {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await result.json();
    if (data) {
      toast.success(data.message);
      handleClose()
      dispatch(getSubAdminsAction());
    } else {
      toast.error(data.message);
    }
  };

  return (
    <>
      <TableContainer
        // sx={{fontFamily: poppins.style.fontFamily }}
        className={"mt-5 rounded-xl"}
        component={Paper}
        // sx={{ overflowX: "auto" }}
      >
        <Table
          // sx={{fontFamily: poppins.style.fontFamily }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                {/* <input type='checkbox' /> */}
              </TableCell>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                User name
              </TableCell>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                Email address
              </TableCell>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                Phone number
              </TableCell>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                Joined on{" "}
              </TableCell>
              <TableCell
                align="center"
                sx={
                  {
                    // fontFamily: poppins.style.fontFamily
                  }
                }
                className="font-semibold md:text-base text-xs"
              >
                Account status{" "}
              </TableCell>

              <TableCell
                align="center"
                className="font-semibold md:text-base text-xs"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subAdminsLoading ? (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell colSpan={7} align="center">
                  <ListLoader />
                </TableCell>
              </TableRow>
            ) : subAdmins?.data?.length > 0 ? (
              subAdmins?.data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center"></TableCell>
                  <TableCell
                    align="center"
                    className="flex flex-col gap-2 items-center justify-center"
                    style={{ display: "flex" }}
                    component="th"
                    scope="row"
                  >
                    <img
                      width={500}
                      height={500}
                      alt=""
                      src={
                        row?.profile_picture?.url
                          ? row?.profile_picture?.url
                          : person
                      }
                      className="w-8 h-8 rounded-full"
                    />
                    <div className="flex flex-col">
                      <button
                        onClick={() => {
                          setOpen(true);
                          setSelectedSubAdmin(row);
                        }}
                      >
                        <p className="md:text-sm text-[10px] font-semibold hover:text-blue-500">
                          {row.first_name} {row.last_name}
                        </p>
                      </button>
                      <p className="md:text-xs text-[8px]  font-light text-gray-400">
                        {moment(row?.created_on).format("dddd, MMMM Do YYYY")}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell align="center" className="md:text-base text-xs">
                    {row.email}
                  </TableCell>
                  <TableCell align="center" className="md:text-base text-xs">
                    {row.phone}
                  </TableCell>
                  <TableCell align="center" className="md:text-base text-xs">
                    {moment(row?.meta?.created_on).format("dddd, MMMM Do YYYY")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color:
                        row.status === "active"
                          ? "#65A051"
                          : row.status === "suspended"
                          ? "#F2A50F"
                          : "#F14040",
                    }}
                    className="flex items-center justify-center"
                  >
                    {row.status === "active" ? (
                      <img
                        width={800}
                        height={800}
                        alt=""
                        src={active}
                        className="w-16 h-16  object-contain"
                      />
                    ) : row.status === "suspended" ? (
                      <img
                        width={800}
                        height={800}
                        alt=""
                        src={suspended}
                        className="w-16 h-16  object-contain"
                      />
                    ) : (
                      <img
                        width={800}
                        height={800}
                        alt=""
                        src={row.status}
                        className="w-16 h-16  object-contain"
                      />
                    )}
                  </TableCell>

                  <TableCell align="center" className="md:text-base text-xs">
                    <div className="flex gap-2 items-center justify-center">
                      <button onClick={() => handleEdit(row)}>
                        <FaEdit size={20} color="#0070de" />
                      </button>
                      <button onClick={() => handleDelete(row.id)}>
                        <MdOutlineDelete size={25} color="red" />
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell colSpan={7} align="center">
                  No data yet.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 0 && (
        <div className="pagination_box">
          <Pagination
            currentPage={skip}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            getPageNumbers={getPageNumbers}
          />
        </div>
      )}

<Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this subadmin?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={() => deleteSubadmin(selectedSubAdminId)}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
