import { combineReducers } from "redux";
import Authsection from "./auth/reducer";
import { traderSignupReducer } from "./traderSignupSlice";
import ProfileSection from "./profile/reducer";
import Adminsection from "./admin/reducer";
import GuardSection from "./guard/reducer";
import ClientSection from "./client/reducer";
import JobSection from "./job/reducer";
import SubadminSection from "./subadmin/reducer";

const reducers = combineReducers({
  traderSignUp: traderSignupReducer,
  Authsection,
  ProfileSection,

  ClientSection,

  Adminsection,
  GuardSection,
  JobSection,
  SubadminSection
});

export default reducers;
